import React, { useEffect, useState } from "react";
import HeaderDesktop from "./HeaderDesktop";
import HeaderMobile from "./HeaderMobile";
import ScrollDesktop from "./ScrollDesktop";
import { AnimatePresence, motion } from "framer-motion";

export default function Header({ isOpen, setOpen }) {
  const [isVisible, setIsVisible] = useState(true);
  const listenToScroll = () => {
    let heightToHideFrom = 800;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHideFrom) {
      isVisible && // to limit setting state only the first time
        setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  });
  return (
    <div
      className={`${
        isOpen ? "fixed" : "relative"
      } fixed w-full top-0 left-0 right-0 z-50`}
    >
      <HeaderDesktop />
      <HeaderMobile isOpen={isOpen} setOpen={setOpen} />
      <AnimatePresence>
        {!isVisible && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            id="hide"
            className="bg-yellow-500 w-screen top-0 left-0 right-0 fixed"
          >
            <ScrollDesktop />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
