import React from "react";
import { routes } from "./route";
import LOGO from "../../assets/logo/logo.png";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
export default function ScrollDesktop() {
  return (
    <ul className="relative hidden lg:flex lg:items-center gap-5 text-sm bg-black h-[80px] py-2 px-20 flex justify-between items-center">
      <div className="flex">
        <Link
          to="/"
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          <img src={LOGO} alt="logo" className="h-[60px]" />
        </Link>
      </div>

      <div className="flex gap-8">
        {routes.map((route) => {
          const { href, title, hash } = route;
          return (
            <li>
              {hash ? (
                <HashLink
                  smooth
                  to={href}
                  className="flex items-center gap-1 hover:text-neutral-400 transition-all text-white text-base font-thin"
                >
                  {title}
                </HashLink>
              ) : (
                <Link
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  key={href}
                  to={href}
                  className="flex items-center gap-1 hover:text-neutral-400 transition-all text-white text-base font-thin"
                >
                  {title}
                </Link>
              )}
            </li>
          );
        })}
      </div>
    </ul>
  );
}
