import React from "react";
import LeftImgCard from "./LeftImgCard";
import RightImgCard from "./RightImgCard";
import AiConsultation from "./svg/AiConsultation";
import Development from "./svg/Development";
import Marketing from "./svg/Marketing";
import WebDesign from "./svg/WebDesign";
import TitleTxt from "./TitleTxt";

export default function Solutions() {
  const array = [
    {
      id: 1,
      title: <TitleTxt first="WEB" second="DESIGN" />,
      desp: "Looking for top-notch website development? You've come to the right place. With a passion for creating pixel-perfect sites and a knack for smooth functionality, our team is here to build the website of your dreams.",
      img: <WebDesign />,
    },
    {
      id: 2,
      title: <TitleTxt first="AI" second="CONSULTATION" />,
      desp: "Supercharge your business with AI. ChatGPT is just the beginning. Harness the power of Language Models (LLMs), Visual generative tools, Natural Language Processing tools, AR / VR and many more. From strategy to implementation, we’re your true human friend in the AI world.",
      img: <AiConsultation />,
    },
    {
      id: 3,
      title: <TitleTxt first="WEB & MOBILE" second="APP DEVELOPMENT" />,
      desp: "Bring your digital ideas to life with, user-friendly web and mobile applications. Turn your vision into reality with latest technology stack. From sleek designs to seamless functionality, we’ve got the expertise to make your app stand out in the digital crowd.",
      img: <Development />,
    },
    {
      id: 4,
      title: <TitleTxt first="DIGITAL MARKETING" second="& BRANDING" />,
      desp: "Ignite a digital revolution with your brand. With a blend of creative brilliance and data-driven strategies, we’ll launch your brand into the spotlight. Be it content marketing, SEO, social media management (SMM), pay-per-click advertising (PPC), or email marketing we’ve got your back.",
      img: <Marketing />,
    },
  ];
  return (
    <div className="bg-black w-full flex justify-center pt-40">
      <div className="w-[90%] max-w-screen-2xl">
        <p className="text-white text-7xl sm:text-8xl  xl:text-8xl font-thin text-center">
          BESPOKE SOLUTIONS FOR YOUR SUCCESS
        </p>
        <LeftImgCard 
          title={array[0].title}
          img={array[0].img}
          desp={array[0].desp}
        />
        <RightImgCard
          title={array[1].title}
          img={array[1].img}
          desp={array[1].desp}
        />
        <LeftImgCard
          title={array[2].title}
          img={array[2].img}
          desp={array[2].desp}
        />
        <RightImgCard
          title={array[3].title}
          img={array[3].img}
          desp={array[3].desp}
        />
      </div>
    </div>
  );
}
