import React from "react";

export default function TitleTxt({ first, second }) {
  return (
    <>
      {first} <br className="hidden md:block" />
      {second}
    </>
  );
}
