import React from "react";

export default function Toggle({showInfra, setShowInfra}) {
  return (
    <div className="w-full bg-black flex justify-center items-center">
      <div className="w-auto flex flex-col md:flex-row">
        <div className="mx-5 relative md:mb-0 mb-8 flex justify-center items-center">
          <button
            className="text-white text-3xl font-base relative "
            onClick={() => {
              setShowInfra(false);
            }}
          >
            BIM FOR BUILDINGS
          </button>
          {!showInfra && (
            <div className="absolute w-full bottom-[-20px] z-10 flex flex-col items-center">
              <div className="bg-primary w-full h-[5px] mt-2" />
              <div class="w-8 overflow-hidden inline-block">
                <div class="h-4 w-4 bg-primary -rotate-45 transform origin-top-left"></div>
              </div>
            </div>
          )}
        </div>
        <div className="mx-5 relative">
          <button
            className="text-white text-3xl font-base relative"
            onClick={() => {
              setShowInfra(true);
            }}
          >
            BIM FOR INFRASTRUCTURE
          </button>
          {showInfra && (
            <div className="absolute w-full bottom-[-20px] z-10 flex flex-col items-center">
              <div className="bg-primary w-full h-[5px] mt-2" />
              <div class="w-8 overflow-hidden inline-block">
                <div class="h-4 w-4 bg-primary -rotate-45 transform origin-top-left"></div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
