import React from "react";
import { HashLink } from "react-router-hash-link";

export default function ContactNav() {
  return (
    <div className="bg-black flex justify-center pt-40">
      <div className="w-[90%] lg:w-[70%] max-w-screen-2xl px-10 lg:px-0 flex flex-col justify-center">
        <p className="text-white text-2xl lg:text-4xl font-extralight text-center">
          We're here to be your go-to squad for all things tech and AI! Whether
          you're just getting started or looking to fine-tune your digital game,
          we've got you covered. We're the dream team that turns innovative tech
          concepts into reality and crafts AI strategies from start to finish .
          Together, let’s win in this fast-paced digital age !
        </p>
        <HashLink
          smooth
          to="/ai#contact"
          className="text-primary text-2xl lg:text-4xl text-center mt-10"
        >
          
          CONTACT US
          <div className=" flex justify-center ">
          <div className="bg-primary h-[2px] w-[15%] my-2 " />
          </div>
         
        </HashLink>
      </div>
    </div>
  );
}
