import React, { useState } from "react";
import SVC from "../../../assets/bim/svc.png";
export default function Services() {
  const items1 = [
    {
      id: 0,
      title: "BIM 3D Modelling (LOD 100 to 500)",
      desp: "Provide models in specified Revit versions, Revit Fabrication, NWC, DWG, or IFC formats as per project requirements.",
      details: [
        "Incorporate comprehensive level of detail (LOD) ranging from LOD 100 to LOD 500 for accurate representation.",
        "Generate models and develop family libraries for all trades ",
        "Create 4-D and 5-D BIM data-rich models to facilitate scheduling, quantity take-offs, and accurate cost estimations.",
        "Transform 2D drawings into informative and detailed 3D models for enhanced visualization and planning.",
        "Utilize BIM models as essential input for Digital Twin technology, optimizing facility management processes.",
      ],
    },
    {
      id: 1,
      title: "3D Clash coordination",
      desp: "",
      details: [
        "Identify and resolve clashes within 3D models across disciplines (MEP, architectural).",
        "Generate detailed clash reports outlining location, severity, and proposed solutions.",
        "Utilize advanced software (e.g., Navisworks, BIM 360) for efficient clash detection.",
        "Conduct regular coordination meetings to streamline clash resolution efforts.",
        "Enhance project efficiency by proactively addressing clashes during the design phase.",
      ],
    },
    {
      id: 2,
      title: "Shop drawing generation",
      desp: "",
      details: [
        "Precise, detailed representations of construction elements, for accurate implementation on-site.",
        "Derived from BIM models, these drawings seamlessly integrate with the overall design, allowing for efficient coordination and construction.",
        "Tailored to specific trades and providing crucial details necessary for on-site installation.",
        "Crucial communication tool between designers, contractors, and fabricators, reducing errors and rework.",
      ],
    },
    {
      id: 3,
      title: "As-Built modeling",
      desp: "",
      details: [
        "Redline markups seamlessly transformed into As-Built drawings.",
        "Integration of all ASIs and RFIs for comprehensive and accurate models.",
        "Detailed construction documents with distinct red markups for record-keeping.",
        "As-Built markups thoughtfully incorporated into models for the benefit of GC and owners.",
      ],
    },
    {
      id: 4,
      title: "CAD to BIM",
      desp: "",
      details: [
        "Seamless conversion of CAD files to intelligent, data-rich BIM models.",
        "Retain design intent while enhancing models with BIM-specific information.",
        "Expertise in handling various CAD formats for accurate BIM representation.",
        "Streamlined workflow for efficient transition from 2D CAD to 3D BIM.",
        "Integration of CAD to BIM process for improved collaboration and project efficiency.",
      ],
    },
    {
      id: 5,
      title: "BIM family content creation",
      desp: "",
      details: [
        "Revit, Civil 3D and Infraworks families for all trades",
        "Created with desired level of detail ranging from LOD 100 to 500 ",
        "Delivers in multiple formats including Revit versions, NWC, and other IFC formats",
      ],
    },
    {
      id: 6,
      title: "Point cloud modeling",
      desp: "",
      details: [
        "Transform scanned data into highly detailed 3D BIM models, equipped with scheduling, Bills of Materials (BOMs), and accurate cost estimates.",
        "Streamlined Parametric BIM Modeling by thoroughly cleaning and optimizing for seamless integration into parametric Revit models, guaranteeing precision and accuracy.",
        "Digitize Request for Information (RFI) processes, eliminating paperwork and ensuring a streamlined workflow, leading to more efficient project management.",
      ],
    },
    {
      id: 7,
      title: "Visualization & Virtual reality",
      desp: "",
      details: [
        "photorealistic 3D renderings, providing virtual walk-through of the projects.",
        "Integrating AR (Augmented Reality) & VR (Virtual Reality) for lean construction and next level facility management",
      ],
    },
    {
      id: 8,
      title: "Scan to BIM",
      desp: "",
      details: [
        "Create highly accurate 3D models of existing structures or environments using laser scanning technology, LIDAR, and other advanced methods.",
        "Capture millions of data points, providing a detailed and comprehensive dataset for modeling purposes",
        "Facilitate efficient retrofitting and renovation projects by providing accurate as-built models derived from laser scans and LIDAR data.",
      ],
    },
    {
      id: 9,
      title: "4D, 5D Simulations & more",
      desp: "",
      details: [
        "Visualize project schedules in 4D, integrating time-based data with 3D models",
        "Associate cost data with the 3D model, enabling real-time cost estimation and budget tracking.",
        "Evaluate the environmental impact of the project over its entire lifecycle.",
        "Integrate BIM models with Facility Management Systems (FMS) for streamlined operations and maintenance.",
      ],
    },
  ];
  // const items2 = [
  //   {
  //     id: 1,
  //     title:
  //       "Incorporate comprehensive level of detail (LOD) ranging from LOD 100 to LOD 500 for accurate representation. ",
  //   },
  //   {
  //     id: 2,
  //     title: "Generate models and develop family libraries for all trades ",
  //   },
  //   {
  //     id: 3,
  //     title:
  //       "Create 4-D and 5-D BIM data-rich models to facilitate scheduling, quantity take-offs, and accurate cost estimations.",
  //   },
  //   {
  //     id: 4,
  //     title:
  //       "Transform 2D drawings into informative and detailed 3D models for enhanced visualization and planning.",
  //   },
  //   {
  //     id: 5,
  //     title:
  //       "Utilize BIM models as essential input for Digital Twin technology, optimizing facility management processes.",
  //   },
  // ];
  const [popUpData, setPopUpData] = useState(items1[0]);

  const checkText = (txt) => {
    var regExp = /\(([^)]+)\)/;
    var mainTxt = txt.replace(/ *\([^)]*\) */g, "");
    var matches = regExp.exec(txt);
    var subTxt = matches !== null ? `(${matches[1]})` : ""
    var rtnVal = [
      mainTxt, subTxt
    ]
    return rtnVal;
  }
  return (
    <div className="flex w-full justify-center items-center bg-black h-auto">
      <div className="max-w-screen-2xl grid grid-cols-6 w-full h-auto py-40 px-10">
        <div className="col-span-6 lg:col-span-1 lg:pr-10 flex flex-col items-center lg:items-start">
          {items1.map((data) => (
            <>
              <p
                onClick={() => {
                  setPopUpData(data);
                }}
                key={data.id}
                className={`text-center lg:text-start text-white text-2xl  py-3 cursor-pointer ${popUpData.title === data.title ? "font-bold" : "font-thin"
                  }`}
              >
                {checkText(data.title)[0]}&#160;
                <span className={`${popUpData.title === data.title ? "font-light" : "font-thin"}`}>
                  {checkText(data.title)[1]}
                </span>
              </p>
              {popUpData.title === data.title && (
                <div className="bg-primary h-[2px] w-[50%] my-2" />
              )}
            </>
          ))}
        </div>
        <div className="col-span-6 h-auto lg:col-span-3 bg-white">
          <img src={SVC} alt="svc" className="h-full w-full object-cover bg-black" />
        </div>
        <div className="col-span-6 lg:col-span-2 lg:pl-10 flex items-center mt-10 lg:mt-0">
          <div className="">
            <p className="text-white text-4xl font-medium">
              {checkText(popUpData.title)[0]}&#160;
              <span className="font-light">
                {checkText(popUpData.title)[1]}
              </span>
            </p>
            <p className="text-white text-2xl  py-5 font-light ">
              {popUpData.desp}
            </p>
            {popUpData.details.map((data) => (
              <div className="flex items-start w-full mb-5 " key={data}>
                <div className="bg-primary h-[2px] w-[8%] my-3" />
                <p className="text-white text-xl font-extralight pl-2 w-[92%]">
                  {data}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
