import React, { useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
export default function Contact() {
  const [formData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
    phone: Yup.string().required("Required"),
    message: Yup.string().required("Required"),
  });
  function objectToFormData(obj) {
    const formData = new FormData();

    Object.entries(obj).forEach(([key, value]) => {
      formData.append(key, value);
    });

    return formData;
  }

  const handleSubmit = async (values) => {
    setLoading(true);
    console.log("values", values);
    const data = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      message: values.message,
    };
    console.log(data);
    const fData = objectToFormData(data);
    console.log(fData);
    fetch(
      "https://script.google.com/macros/s/AKfycbzt_pRvlcq8d9veUVfcQuhAy2lzw_VvGZFYme3GDFsfLSEY30Ck4VjhrvrsTqkZb8lL/exec",
      {
        method: "POST",
        body: fData,
      }
    );
    toast.info("Message send successfully!");
    setLoading(false);
  };
  return (
    <div className="w-full flex justify-center bg-black pt-[100px] pb-20">
      <div className="max-w-screen-2xl pb-10 px-10">
        <p className="text-white text-5xl sm:text-7xl md:text-8xl xl:text-9xl text-center font-thin">
          CONTACT US
        </p>
        <p className="text-white text-2xl sm:text-3xl text-center  px-2 sm:px-20 py-2 font-light">
          If you have any questions or feedback about Atrius Technologies, our
          team is always here to help. We'll get back to you as soon as possible
          and do our best to address any concerns you may have.
        </p>
        <div className="flex justify-center w-full mt-10">
          <Formik
            className=""
            initialValues={formData}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values);
              resetForm();
            }}
          >
            <Form className="grid grid-cols-1 w-90% sm:w-[70%]">
              <div className="flex flex-col gap-9">
                <div className="p-5">
                  <div className="mb-5 flex flex-col gap-6 md:flex-row">
                    <div className="w-full md:w-1/2">
                      <label className="text-white font-thin ">Your Name</label>
                      <Field
                        name="name"
                        placeholder="Enter your name"
                        className="my-2 w-full text-white rounded-md border-[1px] bg-black py-3 px-5 font-base outline-none focus:border-primary active:border-primary"
                      />
                      <ErrorMessage
                        component="a"
                        className="text-danger"
                        name="name"
                      />
                    </div>
                    <div className="w-full md:w-1/2">
                      <label className="text-white font-thin">Your Email</label>
                      <Field
                        name="email"
                        placeholder="Enter your email"
                        className="my-2 w-full text-white rounded-md border-[1px] bg-black py-3 px-5 font-base outline-none focus:border-primary active:border-primary"
                      />
                      <ErrorMessage
                        component="a"
                        className="text-danger"
                        name="email"
                      />
                    </div>
                  </div>
                  <div className="mb-5 flex flex-col gap-6 md:flex-row ">
                    <div className="w-auto md:w-1/2">
                      <label className="text-white font-thin">Your Phone</label>
                      <Field
                        name="phone"
                        placeholder="Enter your phone number"
                        className="my-2 w-full text-white rounded-md border-[1px] bg-black py-3 px-5 font-base outline-none focus:border-primary active:border-primary"
                      />
                      <ErrorMessage
                        component="a"
                        className="text-danger"
                        name="phone"
                      />
                    </div>
                    <div className="w-full md:w-1/2">
                      <label className="text-white font-thin">Message</label>
                      <Field
                        name="message"
                        placeholder="Type Here"
                        className="my-2 w-full text-white rounded-md border-[1px] bg-black py-3 px-5 font-base outline-none focus:border-primary active:border-primary"
                      />
                      <ErrorMessage
                        component="a"
                        className="text-danger"
                        name="message"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="flex w-auto justify-center rounded-full bg-primary px-[80px] sm:px-[120px] py-5 sm:py-6 text-2xl font-medium text-white"
                >
                  {loading ? (
                    <div className="flex h-auto items-center justify-center ">
                      <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                    </div>
                  ) : (
                    "Send"
                  )}
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}
