import { BsBuildingsFill } from "react-icons/bs";
import { MdOutlineConnectWithoutContact } from "react-icons/md";
import { GiBrain } from "react-icons/gi";
import { SiSoundcharts, SiBookstack } from "react-icons/si";
import { BiLogoLinkedin, BiLogoTwitter, BiLogoFacebook } from "react-icons/bi";
import { TfiYoutube } from "react-icons/tfi";

export const routes = [
  {
    title: "ABOUT US",
    href: "/home#about",
    Icon: SiBookstack,
    hash: true,
  },
  {
    title: "BIM",
    href: "/bim",
    Icon: BsBuildingsFill,
    hash: false,
  },
  {
    title: "QOMPAS",
    href: "https://qompas.co/",
    Icon: SiSoundcharts,
    hash: false,
  },
  {
    title: "AI",
    href: "/ai",
    Icon: GiBrain,
    hash: false,
  },
  {
    title: "CONTACT",
    href: "/home#contact",
    Icon: MdOutlineConnectWithoutContact,
    hash: true,
  },
];

export const social = [
  {
    title: "LinkedIn",
    href: "https://www.linkedin.com/",
    Icon: BiLogoLinkedin,
  },
  {
    title: "Twitter",
    href: "https://twitter.com/",
    Icon: BiLogoTwitter,
  },
  {
    title: "YouTube",
    href: "https://www.youtube.com/",
    Icon: TfiYoutube,
  },
  {
    title: "FaceBook",
    href: "http://facebook.com/",
    Icon: BiLogoFacebook,
  },
];
