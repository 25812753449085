import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Ai from "../pages/ai/Ai";
import Bim from "../pages/bim/Bim";
import Home from "../pages/home/Home";
import NotFound from "../pages/notFound/NotFound";
import "./Layout.scss";

export default function Layout() {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className={`${isOpen ? "fixed" : "relative"}`}>
      <BrowserRouter>
        <div className="absolute w-full top-0">
          <Header isOpen={isOpen} setOpen={setOpen} />
        </div>
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="home" element={<Home />} />
            <Route path="bim" element={<Bim />} />
            <Route path="ai" element={<Ai />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
        <div className="footer">
          <Footer />
        </div>
      </BrowserRouter>
    </div>
  );
}
