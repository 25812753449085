import React from "react";
import ContactNav from "../../components/ai/contact/ContactNav";
import Contact from "../../components/home/contact/Contact";
import Hero from "../../components/ai/hero/Hero";
import "./Ai.scss";
import Solutions from "../../components/ai/solutions/Solutions";

export default function Ai() {
  return (
    <div>
      <Hero />
      <ContactNav />
      <Solutions />
      <div id="contact">
        <Contact />
      </div>
    </div>
  );
}
