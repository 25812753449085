import React from "react";
import ABOUT from "../../../assets/home/about-us.png";
export default function About() {
  return (
    <div className="bg-black pt-[100px]">
      <div className="flex justify-center">
        <div className="w-full max-w-screen-2xl mx-10 lg:mx-50 lg:20 grid grid-cols-1 lg:grid-cols-3 gap-4">
          <div className="col-span-1 bg-grey-500">
            <div className="flex gap-5 lg:flex-col lg:gap-0">
              <p className="text-white text-5xl sm:text-7xl md:text-8xl xl:text-9xl text-start font-thin">
                ABOUT
              </p>
              <p className="text-white text-5xl sm:text-7xl md:text-8xl xl:text-9xl text-start font-thin">
                US
              </p>
            </div>
          </div>
          <div className="col-span-2">
            <p className="text-white text-3xl text-start font-normal">
              Our journey began with a vision to bridge the gap between
              traditional construction practices and the digital future.
            </p>
            <p className="text-white text-xl text-start font-light mt-5">
              At Atrius Technologies we're on a mission to revolutionize the
              construction industry through cutting-edge technology and expert
              consultation.
            </p>
            <p className="text-white text-xl text-start font-light mt-5">
              With a dynamic trio of services - BIM Consultation & Design,
              Construction Technology Platform, and Software Development & AI
              Consultation - we offer comprehensive solutions tailored to meet
              the evolving needs of modern construction projects.
            </p>
            <p className="text-white text-xl text-start font-light mt-5">
              Whether you're embarking on a new project or seeking to optimize
              existing processes, Atrius Technologies s your dedicated partner
              in digital transformation.
            </p>
          </div>
        </div>
      </div>
      <div className="pt-10">
        <img src={ABOUT} alt="about" className="w-full" />
      </div>
    </div>
  );
}
