import React from "react";
import sewerage from "../../../assets/bim/sewerage.png";
import storm from "../../../assets/bim/storm.png";
import road from "../../../assets/bim/road.png";
import water from "../../../assets/bim/water.png";
import pipe from "../../../assets/bim/pipe.png";
import gas from "../../../assets/bim/gas.png";
import fire from "../../../assets/bim/fire.png";
import cool from "../../../assets/bim/cool.png";
import light from "../../../assets/bim/light.png";
import tele from "../../../assets/bim/tele.png";
import cctv from "../../../assets/bim/cctv.png";
import power from "../../../assets/bim/power.png";
import land from "../../../assets/bim/land.png";
import traffic from "../../../assets/bim/traffic.png";
import sign from "../../../assets/bim/sign.png";
export default function Infra() {
  const array = [
    {
      id: 1,
      title: "Sewerage",
      img: sewerage,
    },
    {
      id: 2,
      title: "Storm water",
      img: storm,
    },
    {
      id: 3,
      title: "Road network",
      img: road,
    },
    {
      id: 4,
      title: "Potable water",
      img: water,
    },
    {
      id: 5,
      title: "Irrigation network & Pumping station",
      img: pipe,
    },
    {
      id: 6,
      title: "Gas network",
      img: gas,
    },
    {
      id: 7,
      title: "Fire fighting & Pumping stations",
      img: fire,
    },
    {
      id: 8,
      title: "District Cooling",
      img: cool,
    },
    {
      id: 9,
      title: "Streetlight Network",
      img: light,
    },
    {
      id: 10,
      title: "Telecom & Meet me room",
      img: tele,
    },
    {
      id: 11,
      title: "CCTV",
      img: cctv,
    },
    {
      id: 12,
      title: "Power network",
      img: power,
    },
    {
      id: 13,
      title: "Landscape Irrigation",
      img: land,
    },
    {
      id: 14,
      title: "Traffic Signals",
      img: traffic,
    },
    {
      id: 15,
      title: "Signages",
      img: sign,
    },
  ];
  return (
    <div className="bg-black w-full flex justify-center">
      <div className="max-w-screen-2xl">
        <p className="text-white text-3xl sm:text-6xl lg:text-8xl font-thin text-center px-5 lg:px-20 my-20">
          COMPREHENSIVELY COVER EVERY ASPECT OF YOUR INFRASTRUCTURE PROJECTS
        </p>
        <div className="grid  grid-cols-1 sm:grid-cols-3 lg:grid-cols-5 gap-4 px-5 lg:px-20">
          {array.map((data) => (
            <div className="flex flex-col items-center justify-top w-full mb-10" key={data.id}>
              <img src={data.img} alt="icon" className="h-[120px]" />
              <p className="text-white text-2xl font-extralight text-center py-5">{data.title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
