import React, { useState } from "react";
import Buildings from "../../components/bim/buildings/Buildings";
import Hero from "../../components/bim/hero/Hero";
import Image from "../../components/bim/img/Image";
import Image2 from "../../components/bim/img/Image2";
import Image3 from "../../components/bim/img/Image3";
import Infra from "../../components/bim/infra/Infra";
import Services from "../../components/bim/services/Services";
import Toggle from "../../components/bim/toggle/Toggle";
import Contact from "../../components/home/contact/Contact";
import "./Bim.scss";

export default function Bim() {
  const [showInfra, setShowInfra] = useState(false);

  return (
    <div>
      <Hero />
      <Services />
      <Image />
      <Toggle showInfra={showInfra} setShowInfra={setShowInfra} />
      {showInfra ? <Infra /> : <Buildings />}
      <Image2 />
      <Image3 />
      <Contact />
    </div>
  );
}
