import React from "react";
import Hero from "../../components/home/hero/Hero";
import About from "../../components/home/about/About";
import Service from "../../components/home/service/Service";
import Contact from "../../components/home/contact/Contact";
import "./Home.scss";
export default function Home() {
  return (
    <div>
      <Hero />
      <Service />
      <div id="about">
        <About />
      </div>
      <div id="contact">
        <Contact />
      </div>
    </div>
  );
}
