import React from "react";

export default function RightImgCard({ title, img, desp }) {
  return (
    <div className="w-full grid grid-cols-1 md:grid-cols-2 grid-flow row md:grid-flow-col gap-4 mt-40">
      <div className="px-10 flex justify-center items-center md:items-end flex-col">
        <p className="text-white text-4xl sm:text-5xl font-bold text-center md:text-end">
          {title}
        </p>
        <p className="text-white text-xl font-extralight text-center md:text-end w-3/4 mt-5">
          {desp}
        </p>
      </div>
      <div className="flex justify-center items-center">{img}</div>
    </div>
  );
}
