import React from "react";

export default function Marketing() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="409.556"
        height="396.645"
        viewBox="0 0 409.556 396.645"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_135"
              data-name="Rectangle 135"
              width="409.556"
              height="396.645"
              fill="none"
            />
          </clipPath>
        </defs>
        <g id="Group_110" data-name="Group 110" transform="translate(0 0.002)">
          <g
            id="Group_109"
            data-name="Group 109"
            transform="translate(0 -0.002)"
            clip-path="url(#clip-path)"
          >
            <path
              id="Path_387"
              data-name="Path 387"
              d="M1979.835,1476.675c2.6-3.627,3.288-8.826.862-12.572,1.206,2.658.115,5.935-1.963,7.985-4.251,4.2-10.95,3.861-16.244,5.976,2.5-1,4.9,5.418,7.612,5.272,3.447-.186,7.844-4.026,9.732-6.662"
              transform="translate(-1775.573 -1324.654)"
              fill="#ccc"
            />
            <path
              id="Path_388"
              data-name="Path 388"
              d="M520.711,1617.309a13.689,13.689,0,0,0,8.214,10.929c3.982,1.642,8.469,1.279,12.759.889l105.493-9.608c1.829-.167,7.494.132,9-.926s-1-4.667-2.057-6.171c-10.023-.925-20.5,1.2-30.487,2.437l-79.138,9.8c-5.181.641-10.6,1.257-15.487-.593s-8.932-7.024-7.655-12.085a14.359,14.359,0,0,0,.619,11.106"
              transform="translate(-470.94 -1458.447)"
              fill="#ccc"
            />
            <path
              id="Path_389"
              data-name="Path 389"
              d="M3000.625,3347.915a1.174,1.174,0,0,1-.722-1.5l21.472-61.633a1.176,1.176,0,0,1,2.195-.06c9.276,22.578,16.327,38.291,25.609,60.884a1.174,1.174,0,1,1-2.172.892c-8.932-21.744-15.68-36.836-24.431-58.025l-20.455,58.713a1.174,1.174,0,0,1-1.495.722"
              transform="translate(-2714.116 -2971.217)"
              fill="#fff"
            />
            <path
              id="Path_390"
              data-name="Path 390"
              d="M2112.237,3324c-8.893-21.647-16.288-38.6-25.057-59.854l-20.456,58.712a1.174,1.174,0,1,1-2.217-.772l21.472-61.633a1.174,1.174,0,0,1,2.195-.06c9.244,22.5,16.95,40.118,26.235,62.714a1.174,1.174,0,1,1-2.172.893"
              transform="translate(-1867.814 -2949.199)"
              fill="#fff"
            />
            <path
              id="Path_391"
              data-name="Path 391"
              d="M687.017,2475.073l-11.283,5.344a1.34,1.34,0,0,1-.714,1.533c-1.184.647-2.336,1.348-3.483,2.056a1.522,1.522,0,0,1-.122,2.011,5.084,5.084,0,0,0-.391.359,1.551,1.551,0,0,1-2.155,0,1.325,1.325,0,0,1-.352-.553.8.8,0,0,0-.265.37c-.355,1.929-3.292,1.107-2.939-.81,0-.011.008-.022.01-.033l-7.266,3.442a17.975,17.975,0,0,0,13.633,9.069,16.366,16.366,0,0,0,14.785-6.709,15.032,15.032,0,0,0,.542-16.077"
              transform="translate(-595.38 -2239.333)"
              fill="#ccc"
            />
            <path
              id="Path_392"
              data-name="Path 392"
              d="M274.91,2029.947l16.394,11.84c1.277.922,2.676,1.885,4.251,1.859a7.362,7.362,0,0,0,3.947-1.743q14.619-10.389,28.181-22.166a174.38,174.38,0,0,0-28.671,18.087c-1.242.964-2.591,1.991-4.163,2.034a7.361,7.361,0,0,1-3.815-1.391l-21.91-13.049,8.981,5.763Z"
              transform="translate(-243.493 -1827.367)"
              fill="#ccc"
            />
            <path
              id="Path_393"
              data-name="Path 393"
              d="M187.481,2446.188a304.377,304.377,0,0,0,43.708,4.69,30.3,30.3,0,0,1,1.128-6.533Z"
              transform="translate(-169.624 -2211.532)"
              fill="#ccc"
            />
            <path
              id="Path_394"
              data-name="Path 394"
              d="M868.909,2223.15a1.174,1.174,0,0,1-1.174-1.174v-14.541a1.174,1.174,0,1,1,2.348,0v14.541a1.174,1.174,0,0,1-1.174,1.174"
              transform="translate(-785.087 -1996.124)"
              fill="#fff"
            />
            <path
              id="Path_395"
              data-name="Path 395"
              d="M228.083,2034.927H185.4a1.175,1.175,0,0,1-1.174-1.174v-42.743a1.174,1.174,0,0,1,1.174-1.174h65.1a1.174,1.174,0,0,1,1.174,1.174v13.677a1.174,1.174,0,0,1-2.348,0v-12.5H186.573v40.395h41.509a1.174,1.174,0,0,1,0,2.349"
              transform="translate(-166.678 -1800.312)"
              fill="#fff"
            />
            <path
              id="Path_396"
              data-name="Path 396"
              d="M225.509,2026.806a1.172,1.172,0,0,1-.555-.14,209.891,209.891,0,0,1-32.475-21.495,1.174,1.174,0,0,1,1.475-1.828,207.4,207.4,0,0,0,31.432,20.888,292.3,292.3,0,0,1,31.376-21.02,1.174,1.174,0,1,1,1.2,2.019,286.425,286.425,0,0,0-31.722,21.321,1.171,1.171,0,0,1-.73.255"
              transform="translate(-173.751 -1812.266)"
              fill="#fff"
            />
            <path
              id="Path_397"
              data-name="Path 397"
              d="M200.727,2193.812a1.174,1.174,0,0,1-.407-1.61,161.351,161.351,0,0,1,18.808-25.382,1.174,1.174,0,0,1,1.743,1.575,158.959,158.959,0,0,0-18.534,25.011,1.174,1.174,0,0,1-1.61.406"
              transform="translate(-181.09 -1960.089)"
              fill="#fff"
            />
            <path
              id="Path_398"
              data-name="Path 398"
              d="M642.94,2185.173a1.17,1.17,0,0,1-.92-.444c-4.653-5.853-9.652-11.975-15.118-16.832a1.174,1.174,0,0,1,1.56-1.755c5.606,4.982,10.68,11.192,15.4,17.126a1.174,1.174,0,0,1-.918,1.9"
              transform="translate(-566.836 -1959.558)"
              fill="#fff"
            />
            <path
              id="Path_399"
              data-name="Path 399"
              d="M644.577,2343.942a16.784,16.784,0,1,0,16.784,16.784,16.8,16.8,0,0,0-16.784-16.784m8.29,12.185-10.6,10.6a.782.782,0,0,1-1.107,0l-5.034-5.033a.783.783,0,0,1,1.107-1.107l4.48,4.48,10.042-10.042a.783.783,0,0,1,1.107,1.107"
              transform="translate(-567.999 -2120.692)"
              fill="#fff"
            />
            <path
              id="Path_400"
              data-name="Path 400"
              d="M3468.671,1056.439c2.673,16.641,17.1,27.6,31.88,27.235,10.953-.267,21.581-7.087,26.064-17.084s2.39-22.68-5.322-30.464a30.263,30.263,0,0,1-1.773,29.761,28.844,28.844,0,0,1-26.955,12.391,27.557,27.557,0,0,1-22.508-19.089Z"
              transform="translate(-3138.296 -937.44)"
              fill="#ccc"
            />
            <path
              id="Path_401"
              data-name="Path 401"
              d="M3742.554,1168.765a2.949,2.949,0,1,1-2.873-3.12,3,3,0,0,1,2.873,3.12"
              transform="translate(-3380.76 -1054.621)"
              fill="#fff"
            />
            <path
              id="Path_402"
              data-name="Path 402"
              d="M3725.444,1160.438a4.242,4.242,0,0,1,3.1-7.122,4.171,4.171,0,0,1,4.018,4.323c-.057,2.307-4.61,5.526-7.12,2.8m1.793-4.269a1.949,1.949,0,0,0-.065,2.679,1.767,1.767,0,0,0,3.044-1.266,1.825,1.825,0,0,0-1.727-1.918,2.939,2.939,0,0,0-1.252.505"
              transform="translate(-3369.596 -1043.467)"
              fill="#fff"
            />
            <path
              id="Path_403"
              data-name="Path 403"
              d="M3754.809,1025.375a1.175,1.175,0,0,1-1.146-1.2l.362-14.834a1.193,1.193,0,0,1,1.2-1.145,1.175,1.175,0,0,1,1.146,1.2l-.362,14.834a1.175,1.175,0,0,1-1.2,1.145"
              transform="translate(-3396.143 -912.167)"
              fill="#fff"
            />
            <path
              id="Path_404"
              data-name="Path 404"
              d="M3780.266,1230.713l-7.391-7.76a1.174,1.174,0,0,1,1.7-1.62l7.391,7.76a1.174,1.174,0,0,1-1.7,1.62"
              transform="translate(-3413.232 -1104.677)"
              fill="#fff"
            />
            <path
              id="Path_405"
              data-name="Path 405"
              d="M3486.879,886.622a29.63,29.63,0,1,0,28.9,30.339,29.471,29.471,0,0,0-28.9-30.339m-1.251,51.262a21.656,21.656,0,1,1,22.173-21.117,21.681,21.681,0,0,1-22.173,21.117m24.1-13.7h-.019l-.236-.006a.783.783,0,0,1,.019-1.565l.255.006a.783.783,0,0,1-.019,1.565M3511,919.7a.782.782,0,0,1-.567-.951,17.7,17.7,0,0,0-5.16-17.059.783.783,0,1,1,1.05-1.161,19.308,19.308,0,0,1,5.628,18.6.783.783,0,0,1-.951.567"
              transform="translate(-3127.303 -802.166)"
              fill="#fff"
            />
            <path
              id="Path_406"
              data-name="Path 406"
              d="M3983.424,801.949h-.014a1.174,1.174,0,0,1-1.16-1.189,44.288,44.288,0,0,0-22.811-38.675,1.174,1.174,0,0,1,1.11-2.069A46.693,46.693,0,0,1,3984.6,800.79a1.174,1.174,0,0,1-1.174,1.159"
              transform="translate(-3581.76 -687.501)"
              fill="#fff"
            />
            <path
              id="Path_407"
              data-name="Path 407"
              d="M4187.141,784.188a1.176,1.176,0,0,1-1.146-.922,26.431,26.431,0,0,0-9.007-14.693,1.174,1.174,0,0,1,1.489-1.816,28.789,28.789,0,0,1,9.811,16,1.176,1.176,0,0,1-1.147,1.427"
              transform="translate(-3778.76 -693.486)"
              fill="#fff"
            />
            <path
              id="Path_408"
              data-name="Path 408"
              d="M1913.93,3884.549a1.174,1.174,0,0,1-.67-1.519,17.262,17.262,0,0,0,.954-6.685c-.131-4.4.189-6.694.441-11.519a1.174,1.174,0,1,1,2.345.122c-.246,4.69-.566,7.067-.44,11.328a19.543,19.543,0,0,1-1.111,7.6,1.174,1.174,0,0,1-1.519.67"
              transform="translate(-1730.958 -3495.711)"
              fill="#fff"
            />
            <path
              id="Path_409"
              data-name="Path 409"
              d="M3144.138,2293.974c.695.428,3.179,2.182,3.985,2.057,1.469-.228,1.314-3.077,1.286-4.564a4.62,4.62,0,0,1,1.221-3.792c-.823.774-12.409-2.833-13.707-3.147-2.768-.67-9.412-4-11.748-1.738,5.078,3.857,13.746,7.974,18.963,11.185"
              transform="translate(-2827.516 -2064.662)"
              fill="#ccc"
            />
            <path
              id="Path_410"
              data-name="Path 410"
              d="M2428.073,2271.009a6.637,6.637,0,0,0-3.642,6.871c.391,1.985,1.844,3.7,1.919,5.723.064,1.709-.873,3.289-1.156,4.976-.618,3.686,2.075,7.285,5.423,8.946s7.213,1.824,10.948,1.956a2.3,2.3,0,0,0-.662-3.046c-1.863-1.47-5.418-.847-8.346-3a4.864,4.864,0,0,1-2.108-4.741,19.351,19.351,0,0,0,.818-2.646c.178-2.325-1.329-4.74-1.408-7.07-.067-1.984.681-2.75,2.657-2.932,2.784-.256,8.4,3.5,7.21-1.676-.994-4.312-8.485-4.973-11.653-3.361"
              transform="translate(-2193.425 -2053.978)"
              fill="#ccc"
            />
            <path
              id="Path_411"
              data-name="Path 411"
              d="M1186.9,3324.882l-6.287-14.137a3.732,3.732,0,0,0-1.9-2.276c-1.311-.433-2.581.655-3.616,1.568-3.22,2.841-4.844,5.21-1.333,8.78,2.34,2.38,12.155,3.863,13.134,6.065"
              transform="translate(-1060.188 -2993.263)"
              fill="#ccc"
            />
            <path
              id="Path_412"
              data-name="Path 412"
              d="M1762.8,3854.337l1.246-11.766c-.469,4.428-16.215,1.712-18.168,3.144,4.407,4.349,10.967,7.164,16.921,8.622"
              transform="translate(-1579.59 -3476.583)"
              fill="#ccc"
            />
            <path
              id="Path_413"
              data-name="Path 413"
              d="M3004.527,2508.268c3.655.166,7.7-.15,10.3-2.727,3.082-3.057,2.922-8,2.551-12.324-1.146,4.011-2.683,8.495-6.481,10.218-2.214,1-4.762.831-7.183.606-7.529-.7-14.985-2.24-22.484-2.939,1.248,5.655,18.493,6.947,23.3,7.167"
              transform="translate(-2697.281 -2255.749)"
              fill="#ccc"
            />
            <path
              id="Path_414"
              data-name="Path 414"
              d="M2684.436,2144.5c-.646,3.1-1.271,6.343-.384,9.379-1.615-5.53-9.366-13.632-5.355-19.7,5.707-8.634,6.46,6.868,5.74,10.32"
              transform="translate(-2422.532 -1928.57)"
              fill="#ccc"
            />
            <path
              id="Path_415"
              data-name="Path 415"
              d="M2024.094,2171.948a340.978,340.978,0,0,0-57.921-12.026,1.174,1.174,0,0,1-1.028-1.151l-.054-4.5q-.247-20.3-.5-40.6a1.175,1.175,0,0,1,1.274-1.185c20.433,1.729,41.505,2.777,61.963,4.517a1.175,1.175,0,0,1,1.075,1.2c-.128,5.135-.427,10.714-.945,17.556a1.175,1.175,0,0,1-1.546,1.024,34.108,34.108,0,0,0-8.947-2.483,3.7,3.7,0,0,0-2.141.507,1.524,1.524,0,0,0,.249.423,1.174,1.174,0,1,1-1.709,1.61,2.615,2.615,0,0,1,.063-3.92,6.009,6.009,0,0,1,3.738-.959,26.079,26.079,0,0,1,5.614,1.184c1.073.322,1.815.607,2.454.863.409-5.616.664-10.369.792-14.757-19.7-1.648-39.856-2.676-59.569-4.315q.248,19.649.483,39.306l.043,3.476a344.58,344.58,0,0,1,55.827,11.531c.14-4.705.427-9.747.911-16.087a1.175,1.175,0,0,1,2.342.179c-1.406,18.394-.5,17.763-1.434,18.436a1.173,1.173,0,0,1-1.033.169"
              transform="translate(-1777.473 -1911.282)"
              fill="#fff"
            />
            <path
              id="Path_416"
              data-name="Path 416"
              d="M2580.2,2606.661a1.174,1.174,0,0,1,.725-1.494l16.916-5.858c-5.487-.911-10.77-1.777-16.089-2.668a1.174,1.174,0,0,1,.388-2.316c6.77,1.134,13.779,2.278,20.637,3.426a1.176,1.176,0,0,1,.19,2.268l-21.274,7.367a1.174,1.174,0,0,1-1.494-.726"
              transform="translate(-2334.387 -2347.211)"
              fill="#fff"
            />
            <path
              id="Path_417"
              data-name="Path 417"
              d="M2117.352,3212.883c-1.863,5.159-3.8,10.662-5.362,16.118-.064-.177-.031.186-.039-6.333,0-.208-.192.339,2.693-8.963a1.16,1.16,0,0,1,1.119-.822Z"
              transform="translate(-1910.794 -2906.87)"
              fill="#fff"
            />
            <path
              id="Path_418"
              data-name="Path 418"
              d="M2224.049,3212.883V3229.9H2126.77c1.621-5.753,3.695-11.578,5.652-17.018Z"
              transform="translate(-1924.205 -2906.87)"
              fill="#fff"
            />
            <path
              id="Path_419"
              data-name="Path 419"
              d="M3192.145,3214.057v14.669a1.173,1.173,0,0,1-1.174,1.174h-26.412v-17.018h26.412a1.173,1.173,0,0,1,1.174,1.174"
              transform="translate(-2863.149 -2906.87)"
              fill="#fff"
            />
            <path
              id="Path_420"
              data-name="Path 420"
              d="M2947.79,2359.191a210.207,210.207,0,0,1-29.354-5.49,1.174,1.174,0,0,1,.636-2.26,207.855,207.855,0,0,0,29,5.419c6.008.726,7.4-.373,8.859-4.318a76.964,76.964,0,0,0,4.21-19.883,1.174,1.174,0,1,1,2.342.164,79.456,79.456,0,0,1-4.351,20.535c-2.154,5.808-5.294,6.565-11.341,5.833"
              transform="translate(-2639.693 -2109.495)"
              fill="#fff"
            />
            <path
              id="Path_421"
              data-name="Path 421"
              d="M2596.9,2466.056a1.174,1.174,0,0,1,1.252-1.986c19.561,12.335,45.083,15.168,70.146,7.81.491-8.782,1.137-26.54.729-32.067,0-.007-.007-.084-.007-.091a1.2,1.2,0,0,1,1.071-1.269,1.143,1.143,0,0,1,1.263,1.007c0,.022.009.106.011.128.434,5.859-.271,24.751-.769,33.251a1.173,1.173,0,0,1-.832,1.055c-24.568,7.422-51.442,5.67-72.864-7.838m72.524,6.714h0Z"
              transform="translate(-2349.062 -2206.197)"
              fill="#fff"
            />
            <path
              id="Path_422"
              data-name="Path 422"
              d="M2711.278,2144.268c-.253-5.289-1.326-14.341-1.583-19.7a1.174,1.174,0,1,1,2.345-.113c.253,5.275,1.326,14.337,1.583,19.7a1.174,1.174,0,1,1-2.345.112"
              transform="translate(-2451.608 -1921.099)"
              fill="#fff"
            />
            <path
              id="Path_423"
              data-name="Path 423"
              d="M2613.829,1763.769a1.175,1.175,0,0,1,.063-1.659,59.623,59.623,0,0,1,5.366-4.41,67.908,67.908,0,0,1,14.633-8.1,1.174,1.174,0,1,1,.88,2.177,66.427,66.427,0,0,0-6.974,3.3,60.854,60.854,0,0,0-7.151,4.521,57.273,57.273,0,0,0-5.157,4.238,1.175,1.175,0,0,1-1.66-.064"
              transform="translate(-2364.591 -1582.88)"
              fill="#fff"
            />
            <path
              id="Path_424"
              data-name="Path 424"
              d="M2465.986,1929.124a12.417,12.417,0,0,1-.146-1.533,14.015,14.015,0,0,1,1.224-6.537,56.335,56.335,0,0,1,10.553-15.494,1.174,1.174,0,1,1,1.7,1.625,54,54,0,0,0-10.115,14.85,12.447,12.447,0,0,0-.9,6.671,1.174,1.174,0,1,1-2.31.42"
              transform="translate(-2230.962 -1723.736)"
              fill="#fff"
            />
            <path
              id="Path_425"
              data-name="Path 425"
              d="M2502.585,2363.724a90.716,90.716,0,0,1-13.983-4.016,1.174,1.174,0,1,1,.835-2.2,88.092,88.092,0,0,0,13.617,3.91,1.174,1.174,0,1,1-.469,2.3"
              transform="translate(-2250.89 -2132.901)"
              fill="#fff"
            />
            <path
              id="Path_426"
              data-name="Path 426"
              d="M2648.465,2289.569q-11.358-1.378-22.707-2.748a17.008,17.008,0,0,1-8.107-3.284c-1.654-1.236-3.422-2.335-5.294-3.5-1.2-.751-2.418-1.66-3.179-1.567.234.3.444.47,4.863,4.931a1.175,1.175,0,0,1-1.112,1.967c-2.251-.549-4.553-.989-6.7-1.382a1.174,1.174,0,1,1,.422-2.31c.948.173,1.927.356,2.918.553q-.821-.827-1.641-1.654c-.423-.417-1.573-1.55-1.143-2.911a2.691,2.691,0,0,1,3.035-1.514,6.269,6.269,0,0,1,2.441,1.056c2.63,1.637,4.82,2.969,6.8,4.448a14.663,14.663,0,0,0,6.983,2.834c24.532,2.953,24.617,3.171,25.959,2.718,1.884-.639,1.8-2.985,1.728-3.688-.217-2.062.144-5.015.086-7.176a1.174,1.174,0,1,1,2.347-.063c.063,2.268-.289,5.164-.1,7,.237,2.354-.509,5.2-3.312,6.153a9.731,9.731,0,0,1-4.287.138"
              transform="translate(-2357.122 -2058.44)"
              fill="#fff"
            />
            <path
              id="Path_427"
              data-name="Path 427"
              d="M2484.743,2366.845a93.548,93.548,0,0,1-13.912-4.189,7.754,7.754,0,0,1-2.489-1.375,3.26,3.26,0,0,1-1.109-3.284,4,4,0,0,1,1.827-2.456c1.3-.556,2.577.532,2,1.636a1.172,1.172,0,0,1-1.15.626,2.933,2.933,0,0,0-.424.85,1.062,1.062,0,0,0,.428.886,5.793,5.793,0,0,0,1.768.929,91.274,91.274,0,0,0,13.566,4.085,1.174,1.174,0,1,1-.508,2.292"
              transform="translate(-2232.144 -2131.05)"
              fill="#fff"
            />
            <path
              id="Path_428"
              data-name="Path 428"
              d="M2487.109,2414.116a43.968,43.968,0,0,1-12.483-3.534,6.1,6.1,0,0,1-2.261-2.165,3.13,3.13,0,0,1-.354-2.694,2.584,2.584,0,0,1,1.781-1.661,1.174,1.174,0,1,1,.654,2.255c-.168.049-.192.112-.212.162a.813.813,0,0,0,.042.574,5.1,5.1,0,0,0,1.28,1.374,41.53,41.53,0,0,0,11.929,3.372,1.174,1.174,0,0,1-.377,2.318"
              transform="translate(-2236.422 -2175.043)"
              fill="#fff"
            />
            <path
              id="Path_429"
              data-name="Path 429"
              d="M2497.91,2457.159c-7.5-.276-13.114-.29-19.394-4.535a5.532,5.532,0,0,1-1.85-3.242c-.567-2.935,2.457-3.884,2.936-2.175a1.175,1.175,0,0,1-.649,1.388,3.454,3.454,0,0,0,.878,2.083c5.42,3.663,10.589,3.853,16.575,4.073a31.3,31.3,0,0,0,8.039-.412,19.471,19.471,0,0,1,9.8.427,1.174,1.174,0,0,1-.685,2.247,17.086,17.086,0,0,0-8.766-.352,33.034,33.034,0,0,1-6.888.5"
              transform="translate(-2240.711 -2213.355)"
              fill="#fff"
            />
            <path
              id="Path_430"
              data-name="Path 430"
              d="M3061.833,2117.647a1.176,1.176,0,0,1-.358-.056,210.776,210.776,0,0,0-37.07-8.356,1.175,1.175,0,0,1-1-.925c-.7-3.371-1.014-6.89-1.7-10.182a1.174,1.174,0,0,1,2.3-.479c.65,3.121.949,6.256,1.538,9.367a212.908,212.908,0,0,1,35.049,7.833,115.607,115.607,0,0,0-3.349-22.471,1.174,1.174,0,1,1,2.278-.571,117.591,117.591,0,0,1,3.485,24.626,1.173,1.173,0,0,1-1.174,1.213"
              transform="translate(-2733.88 -1891.768)"
              fill="#fff"
            />
            <path
              id="Path_431"
              data-name="Path 431"
              d="M2882.394,1743.382a1.175,1.175,0,0,1-1.122-.83c-1.152-3.76-2.537-7.595-4.116-11.4-1.112-2.672-2.4-5.452-4.816-7.5-5.28-4.478-15.34-5.322-21.54-9.727-5.422,4.659-12.221,9.513-17.489,14.135a1.174,1.174,0,0,1-1.94-.736c-.437-3.475-1.743-7.572-2.218-11.216,0-.012,0-.023,0-.035a1.454,1.454,0,0,1-.016-.155,1.174,1.174,0,0,1,2.341-.174c0,.029.008.056.01.083.227,1.731.66,3.6,1.077,5.409.281,1.215.568,2.455.8,3.684,2.412-2.031,5.032-4.079,7.582-6.071,3.084-2.409,6.272-4.9,9.031-7.318a1.175,1.175,0,0,1,1.51-.031,21.288,21.288,0,0,0,5.484,3.016c1.658.668,3.462,1.272,5.207,1.856,4.23,1.415,8.605,2.879,11.683,5.489,2.814,2.386,4.243,5.454,5.465,8.391,1.608,3.872,3.02,7.778,4.194,11.611a1.175,1.175,0,0,1-1.124,1.519"
              transform="translate(-2559.669 -1548.253)"
              fill="#fff"
            />
            <path
              id="Path_432"
              data-name="Path 432"
              d="M2830.441,1683.848h-.01a1.174,1.174,0,0,1-1.164-1.185l.063-7.154a1.174,1.174,0,0,1,1.174-1.164h.011a1.174,1.174,0,0,1,1.164,1.185l-.063,7.154a1.174,1.174,0,0,1-1.174,1.164"
              transform="translate(-2559.792 -1514.872)"
              fill="#fff"
            />
            <path
              id="Path_433"
              data-name="Path 433"
              d="M3119.667,2399.783l-4.71-5.9a1.174,1.174,0,0,1,1.836-1.465l4.711,5.9a1.174,1.174,0,1,1-1.836,1.464"
              transform="translate(-2818.039 -2164.147)"
              fill="#fff"
            />
            <path
              id="Path_434"
              data-name="Path 434"
              d="M3290.558,2414.406a1.174,1.174,0,0,1,.024-1.66.8.8,0,0,0,.018-1.007,1.174,1.174,0,0,1,.16-1.768,2.9,2.9,0,0,0,1.072-3.015,1.174,1.174,0,0,1,2.267-.614,5.267,5.267,0,0,1-1.167,4.813,3.154,3.154,0,0,1-.714,3.275,1.174,1.174,0,0,1-1.66-.025"
              transform="translate(-2976.847 -2176.364)"
              fill="#fff"
            />
            <path
              id="Path_435"
              data-name="Path 435"
              d="M2229.37,1954.653c-2.242-4.256-5.772-10-10.05-19.14a1.174,1.174,0,0,1,2.127-1c4.328,9.242,7.42,14.142,10,19.042a1.174,1.174,0,1,1-2.078,1.094"
              transform="translate(-2007.84 -1749.65)"
              fill="#fff"
            />
            <path
              id="Path_436"
              data-name="Path 436"
              d="M2027.844,2049.027l-3.834-7.2a1.174,1.174,0,0,1,2.073-1.1l3.834,7.2a1.174,1.174,0,0,1-2.073,1.1"
              transform="translate(-1831.107 -1845.79)"
              fill="#fff"
            />
            <path
              id="Path_437"
              data-name="Path 437"
              d="M2007.842,1800.018a1.174,1.174,0,0,1,.016-1.645,3.593,3.593,0,0,0-.035-2.627c-.782-.254-3.112.3-4.279.243-2.107-.1-4.792-1.036-5.438-3.365a4.059,4.059,0,0,1,1.667-4.224,11.018,11.018,0,0,1,3.682-1.589c5.916-1.72,8.15-.869,12.219,3.377a25.983,25.983,0,0,1,3.494,5.343,2.066,2.066,0,0,1,.327,1.194,1.176,1.176,0,0,1-2.178.493,2.435,2.435,0,0,1-.326-.775,23.736,23.736,0,0,0-3.01-4.628c-3.566-3.724-4.964-4.177-9.872-2.751a9.16,9.16,0,0,0-2.917,1.2,1.8,1.8,0,0,0-.825,1.729c.272.981,1.893,1.58,3.28,1.644a10.519,10.519,0,0,0,1.5-.134c2.791-.365,4.649-.4,5.177,2.4.215,1.14.173,3.185-.823,4.143a1.174,1.174,0,0,1-1.66-.032m-.037-4.289h0"
              transform="translate(-1807.687 -1615.87)"
              fill="#fff"
            />
            <path
              id="Path_438"
              data-name="Path 438"
              d="M1910.2,1877.5c-3.572-3.729-5.046-5.434-8.986-9.991-1.374-1.586-2.818-3.04-3.238-4.839a3.577,3.577,0,0,1,2.316-4.355c2.185-.607,4.053,1.134,4.2,1.209a20.268,20.268,0,0,1,3.906,3.463l.361.411c2.052,2.333.982,5.209-1.591,5.26-2.006.04-3.078-1.94-1.774-2.727a1.175,1.175,0,0,1,1.591.364.581.581,0,0,0,.469-.093c.133-.247-.066-.807-.463-1.259l-.348-.4a19.591,19.591,0,0,0-3.931-3.42,2.307,2.307,0,0,0-1.787-.551,1.286,1.286,0,0,0-.661,1.559c.267,1.147,1.4,2.3,2.727,3.837,3.912,4.524,5.375,6.217,8.905,9.9a1.174,1.174,0,1,1-1.7,1.624"
              transform="translate(-1717.108 -1681.199)"
              fill="#fff"
            />
            <path
              id="Path_439"
              data-name="Path 439"
              d="M1985.465,1868.364a1.174,1.174,0,0,1,1.884-1.4c.557.748,2.127,1.428,2.816.687.226-.368-.438-2.089-1.362-2.876a1.174,1.174,0,1,1,1.523-1.787c1.606,1.369,3.116,4.594,1.557,6.266-1.788,1.913-5,1.017-6.417-.887"
              transform="translate(-1796.148 -1685.289)"
              fill="#fff"
            />
            <path
              id="Path_440"
              data-name="Path 440"
              d="M1909.867,1617.106c-.929-1.231-.274-3.208.188-3.973a6.658,6.658,0,0,1,2.854-2.273c.627-1.672,2.44-2.478,4.2-2.816-2.384-3.644-5.05-7.829-7.169-11.659a7.84,7.84,0,0,1-1.1-2.832,3.413,3.413,0,0,1,1.4-3.367,4.19,4.19,0,0,1,4.292.157c1.541.93,1,.539,14.679,18.3a1.174,1.174,0,1,1-1.861,1.433c-13.516-17.553-13.015-17.109-14.029-17.721a1.919,1.919,0,0,0-1.794-.205,1.153,1.153,0,0,0-.355,1.1c.244,1.925,7.928,13.543,9.089,15.311a1.175,1.175,0,0,1-1.358,1.757,5.245,5.245,0,0,0-2.936.555,1.427,1.427,0,0,0-.954,1.191,1.182,1.182,0,0,1-1.248.97,4.651,4.651,0,0,0-1.692,1.307,2.335,2.335,0,0,0-.3,1.392,1.152,1.152,0,0,1-.242,1.624,1.192,1.192,0,0,1-1.657-.25"
              transform="translate(-1726.997 -1438.254)"
              fill="#fff"
            />
            <path
              id="Path_441"
              data-name="Path 441"
              d="M1958.694,1816.519c-.778-.638-5.623-4.521-6.4-5.161a1.174,1.174,0,1,1,1.489-1.816c.778.638,5.623,4.521,6.4,5.161a1.174,1.174,0,1,1-1.489,1.816"
              transform="translate(-1765.954 -1636.95)"
              fill="#fff"
            />
            <path
              id="Path_442"
              data-name="Path 442"
              d="M2982.082,1461.146c-.287-2.48-1.139-4.707-1.686-8.664a1.173,1.173,0,0,1,.671-1.228,4.778,4.778,0,0,0,2.157-2.53l.121-.229a3.873,3.873,0,0,0-.174-4.152c-1.477-2.056-5.377-2.606-5.947-.4a1.251,1.251,0,0,0,.352,1.258,1.174,1.174,0,1,1-1.656,1.666c-2.168-2.155-.909-5.656,2.01-6.482a7,7,0,0,1,7.149,2.591,6.256,6.256,0,0,1,.342,6.622,8.06,8.06,0,0,1-2.581,3.352c.538,3.417,1.286,5.408,1.577,7.928a1.174,1.174,0,0,1-2.333.269"
              transform="translate(-2691.487 -1303.007)"
              fill="#fff"
            />
            <path
              id="Path_443"
              data-name="Path 443"
              d="M2612.086,1231.981a25.425,25.425,0,0,0,.638-6.171c-.358-6.1-4.91-9.261-10.822-10.43-3.328-.651-8.143,1.625-10.81-.767-3.27-2.954-6.552-5.274-10.9-5.945-4.414-.683-9.2.663-11.857,3.859a8.565,8.565,0,0,0,.322,11.311c3.8,3.886,10.526,3.581,13.555-.717-.355.5,2.49,2.153,2.879,2.234a9.966,9.966,0,0,0,4.011.333,75.486,75.486,0,0,0,10.336-.807c.447,3.972,3.963,8.454,7.459,11.434-.895-.862-.607-1.9.136-2.9s3.975-2.047,5.056-1.436"
              transform="translate(-2321.923 -1093.391)"
              fill="#fff"
            />
            <path
              id="Path_444"
              data-name="Path 444"
              d="M2594.851,1226.023c-3.159-2.692-6.664-6.875-7.648-10.982a91.116,91.116,0,0,1-9.333.635,10.745,10.745,0,0,1-4.135-.322l-.1-.021a10.1,10.1,0,0,1-2.748-1.544,10.286,10.286,0,0,1-14.355-.317,9.7,9.7,0,0,1-.385-12.884c2.808-3.373,7.881-5.051,12.939-4.267a18.956,18.956,0,0,1,8.777,3.952c.87.66,1.764,1.407,2.734,2.282,2.121,1.9,6.768-.194,10.248.486,6.5,1.285,11.38,4.9,11.768,11.514a26.4,26.4,0,0,1-.653,6.442,1.182,1.182,0,0,1-1.725.825,5.566,5.566,0,0,0-3.547,1.11c-.7.933-.425,1.2-.262,1.352a1.174,1.174,0,0,1-1.576,1.739m-5.531-12.459c.333,2.955,2.6,6.224,5,8.728a5.775,5.775,0,0,1,.487-.764,7.506,7.506,0,0,1,5.052-2.086c.64-3.6.866-7.194-1.611-10.084a13.817,13.817,0,0,0-7.855-4.011c-3.036-.594-8.211,1.786-11.366-1.045-3.077-2.78-6.2-5.027-10.3-5.658-4.257-.658-8.485.7-10.776,3.45a7.422,7.422,0,0,0,.257,9.738c3.314,3.386,9.168,3.1,11.757-.57l0,0a1.175,1.175,0,0,1,2.116.481,10.743,10.743,0,0,0,2.025,1.294c2.6.453.845.4,5.481.223a58.362,58.362,0,0,0,8.353-.716,1.174,1.174,0,0,1,1.377,1.024m-15.273-.545.02,0-.02,0m-2.161-.407a.015.015,0,0,0,0-.005l0,0h0"
              transform="translate(-2310.641 -1082.205)"
              fill="#fff"
            />
            <path
              id="Path_445"
              data-name="Path 445"
              d="M2696.138,1410.419c-1.8-1.667-2.49-4.075-3.048-6.01a136.153,136.153,0,0,1-4.537-19.228,1.174,1.174,0,1,1,2.327-.316,134.421,134.421,0,0,0,4.467,18.894c.5,1.742,1.072,3.716,2.389,4.939,2.034,1.887,5.3,1.5,7.469.334,2.6-1.391,4.6-3.938,6.373-6.187a1.174,1.174,0,1,1,1.845,1.452c-1.91,2.429-4.076,5.182-7.11,6.806-3.145,1.681-7.458,1.837-10.176-.683"
              transform="translate(-2432.471 -1252.043)"
              fill="#fff"
            />
            <path
              id="Path_446"
              data-name="Path 446"
              d="M2791.512,1576.253a1.174,1.174,0,0,1,.8-2.208,1.5,1.5,0,0,0,1.78-.815,1.174,1.174,0,1,1,2.2.833,3.853,3.853,0,0,1-4.776,2.191"
              transform="translate(-2524.932 -1422.701)"
              fill="#fff"
            />
            <path
              id="Path_447"
              data-name="Path 447"
              d="M2767.044,1476.318a1.174,1.174,0,0,1-.707-.872c-.108-.58-.2-1.1-.28-1.581a22.039,22.039,0,0,0-1.344-5.141,1.174,1.174,0,0,1,2.183-.864,23.479,23.479,0,0,1,1.433,5.361l.478-.332a1.174,1.174,0,1,1,1.339,1.929l-1.985,1.379a1.176,1.176,0,0,1-1.117.121"
              transform="translate(-2501.312 -1327.381)"
              fill="#fff"
            />
            <path
              id="Path_448"
              data-name="Path 448"
              d="M900.325,3332.569c-5.857-2.6-15.373-8.163-19.661-11.993a13.825,13.825,0,0,1-4.392-5.922,6.611,6.611,0,0,1,2.348-7.52,8.366,8.366,0,0,1,6.036-.972,31.677,31.677,0,0,1,5.345,1.249c11.556,4.025,16.054-1.5,17.453-4.062a1.174,1.174,0,1,1,2.061,1.125c-3.507,6.431-11.092,8.357-20.287,5.154a29.71,29.71,0,0,0-4.958-1.15,6.116,6.116,0,0,0-4.338.6,4.339,4.339,0,0,0-1.419,4.864,11.811,11.811,0,0,0,3.717,4.877c4.007,3.58,13.362,9.076,19.05,11.6,4.016,1.784,5.944,1.579,9.6-3.22q2.981-3.937,5.974-7.871a1.174,1.174,0,0,1,1.87,1.421q-3,3.939-5.973,7.87c-1.7,2.228-3.753,4.642-6.729,5.1a10.22,10.22,0,0,1-5.691-1.158"
              transform="translate(-792.5 -2988.165)"
              fill="#fff"
            />
            <path
              id="Path_449"
              data-name="Path 449"
              d="M1510.731,3884.152c-3.338-.048-7.909-.114-11.27-2.432-2.338-1.609-3.544-4.109-3.071-6.369.55-2.618,3.006-4.108,4.42-4.765,1.9-.871,3.848-1.25,5.694-1.908,6.652-2.346,10.16-6.229,10.727-11.87a1.174,1.174,0,1,1,2.336.235c-.657,6.548-4.788,11.207-12.278,13.849-1.872.667-3.823,1.063-5.5,1.828-1.723.8-2.856,1.935-3.1,3.114-.317,1.511.829,3.074,2.105,3.953,2.565,1.77,6.034,1.96,9.971,2.017,13.019.093,19.158,1.067,23.019-2.511a1.174,1.174,0,0,1,1.6,1.722,9.832,9.832,0,0,1-1.139.914c-4.661,3.21-10.615,2.34-23.511,2.223"
              transform="translate(-1353.775 -3488.509)"
              fill="#fff"
            />
            <path
              id="Path_450"
              data-name="Path 450"
              d="M2218.546,2345.917c-.166,2.443-3.132,4.231-6.625,3.994s-6.191-2.409-6.026-4.851,3.132-4.231,6.625-3.994,6.192,2.408,6.026,4.851"
              transform="translate(-1995.786 -2118.071)"
              fill="#fff"
            />
            <path
              id="Path_451"
              data-name="Path 451"
              d="M1679.8,2999.926c-10.075,5.042-20.447,13.026-29.731,22.788-.869,3.914-1.785,8.11-2.708,12.415a272.454,272.454,0,0,0-5.589,27.429c-1.206,6.317-2.231,12.008-2.912,16.408a1.178,1.178,0,0,0,1,1.346c9.958,1.4,21.384,2.346,31.4,1.432a1.172,1.172,0,0,0,1.049-.97c1.738-10.114,5.433-23.735,9.66-38.029,2.183-11.044,7.351-23.86,7.351-24.228,1.6-5.19,2.967-9.652,4.141-13.644-4.995-7.611-7.385-8.1-13.66-4.948m-30.107,49.309a6.57,6.57,0,0,1-1.01,2.372c-.432.721-1.034,1.76-.736,2.568.194.554,1.1,1.658.431,2.716a.786.786,0,0,1-1.331-.838c.142-.5-1.734-1.675-.016-4.728.658-1.114,1.589-2.4.861-3.382a.581.581,0,0,0-.4-.259.785.785,0,1,1,.2-1.558,2.2,2.2,0,0,1,1.448.869,3.05,3.05,0,0,1,.556,2.239m14.912-10.607-5.566,24.29a.773.773,0,0,1-.939.587.784.784,0,0,1-.587-.931l5.566-24.291a.8.8,0,0,1,.939-.595.781.781,0,0,1,.587.939m15.594-18.458c-1.5.606-2.218-.212-1.581,2.114.29,1.018.65,2.294-.321,3.2a2.937,2.937,0,0,1-1.057.587,1.4,1.4,0,0,0-.54.281c-.193.386,1.112,2.009,1.62,3.343,1.414,3.73-2.008,5.673-3.765,4.2a.781.781,0,1,1,1-1.2c.362.293,1.04.018,1.284-.391a2.4,2.4,0,0,0,.016-2.059c-.644-1.656-2.355-3.2-1.519-4.673.511-.879,1.545-.92,1.887-1.237.679-.638-1.4-3.364.235-4.846.7-.634,1.649-.564,2.121-.767a1.624,1.624,0,0,0,.767-1.573,6.311,6.311,0,0,0-.822-2.341.784.784,0,1,1,1.4-.7,7.6,7.6,0,0,1,.979,2.959,3.138,3.138,0,0,1-1.707,3.1"
              transform="translate(-1482.753 -2712.544)"
              fill="#fff"
            />
            <path
              id="Path_452"
              data-name="Path 452"
              d="M1308.16,2669.525c11.3,4.238,23.161,4.843,35.359,4.243,7.022-.344,14.2-1.025,20.627-3.789a1.171,1.171,0,0,0,1.386,1.84,33.566,33.566,0,0,0,3.844-2.294Zm83.948-39.179a1.167,1.167,0,0,0-1.441.822c-.055.18-.109.352-.156.532-16.048,6.153-34.3,2.943-50.694-2.458-3.264-1.08-6.795-2.028-9.942-3.382-1.362-.587-2.505-1.691-3.883-2.317-2.685-1.229-5.582-1.315-8.337-2.3-9.28-3.354-18.881-6.348-28.58-7.569-4.572-.579-11.883-1.965-16.353-.846-3.624.9-6.075,3.288-9.362,5.057-14.427,7.773-26.568,17.144-39.727,26.905q-9.981,7.41-19.406,15.531c-3.938,3.39-16.517,11.961-14.5,14.5,2.981,3.729,5.411,8.379,16.431,27.656a325.093,325.093,0,0,1,38.169-32.275c-.039.2-.086.415-.133.618,8.932-9.049,18.779-16.455,28.361-21.253,6.764-3.394,10.032-2.777,14.936,4.384.008-.031.023-.063.031-.094,4.732,3.983,7.224,9.291,17.073,14.106H1371.5c10.309-8.173,17.926-23.329,21.426-36.173a1.167,1.167,0,0,0-.822-1.44m-121.115-2.137c-17.883,14.963-21.8,18.788-28.2,21.238-2.644,1.008-5.292,1.557-7.413,3.037-4.934,3.466-5.822,11.275-11.233,15.022-2.917,2.021-7.268,2.268-9.683-.407a.789.789,0,0,1,.055-1.111.781.781,0,0,1,1.1.063c1.871,2.074,5.417,1.7,7.64.172,2.505-1.738,4.031-4.65,5.378-7.217,3.742-7.158,5.668-8.376,11.374-10.247.736-.242,1.5-.493,2.215-.767,4.963-1.9,9.128-5.393,13.159-8.768L1269.991,2627a.784.784,0,0,1,1,1.205m20.22-6.278a.784.784,0,0,1-.478-.165,8.751,8.751,0,0,0-5.816-1.808.784.784,0,1,1-.094-1.566,10.355,10.355,0,0,1,6.873,2.137.785.785,0,0,1-.485,1.4m68.464,26.835a70.567,70.567,0,0,1-40.949-3.585.783.783,0,1,1,.6-1.448,69.021,69.021,0,0,0,40.025,3.5.785.785,0,0,1,.329,1.534m16.775-6.419-2.012,3.546a.77.77,0,0,1-.681.4.783.783,0,0,1-.681-1.166l2.012-3.554a.783.783,0,0,1,1.065-.3.793.793,0,0,1,.3,1.072m6.059,3.39-1.519,4.055a.783.783,0,0,1-1.464-.556l1.519-4.047a.77.77,0,0,1,1-.454.78.78,0,0,1,.462,1"
              transform="translate(-1076.216 -2363.583)"
              fill="#fff"
            />
            <path
              id="Path_453"
              data-name="Path 453"
              d="M3193.208,1888.409a1.17,1.17,0,0,1-.732-.257c-2.232-1.784-3.117-4.584-3.589-6.535-.147-.606-.275-1.219-.4-1.832a20.752,20.752,0,0,0-1.3-4.522,9.841,9.841,0,0,0-4.994-4.843,1.174,1.174,0,0,1,.923-2.16,12.22,12.22,0,0,1,6.2,6.013,22.854,22.854,0,0,1,1.469,5.03c.124.589.247,1.178.387,1.761.621,2.568,1.5,4.237,2.773,5.252a1.174,1.174,0,0,1-.734,2.091"
              transform="translate(-2878.455 -1690.232)"
              fill="#fff"
            />
            <path
              id="Path_454"
              data-name="Path 454"
              d="M2843.511,2123.143l-.173-3.315a1.174,1.174,0,0,1,.741-1.152,2.035,2.035,0,0,0,.47-3.508,1.174,1.174,0,1,1,1.408-1.879,4.379,4.379,0,0,1-.234,7.164l.134,2.569a1.174,1.174,0,1,1-2.345.122"
              transform="translate(-2572.521 -1911.795)"
              fill="#fff"
            />
            <path
              id="Path_455"
              data-name="Path 455"
              d="M3055.19,2736.583a1.174,1.174,0,0,1,.384-1.615,5.7,5.7,0,0,0,2.093-2.3,1.174,1.174,0,0,1,2.107,1.037,7.954,7.954,0,0,1-2.968,3.264,1.175,1.175,0,0,1-1.616-.383"
              transform="translate(-2764.039 -2471.797)"
              fill="#fff"
            />
            <path
              id="Path_456"
              data-name="Path 456"
              d="M3137.883,2738.84a1.174,1.174,0,0,1-.142-1.655l3.589-4.261a1.174,1.174,0,1,1,1.8,1.513l-3.589,4.262a1.174,1.174,0,0,1-1.654.142"
              transform="translate(-2838.636 -2472.247)"
              fill="#fff"
            />
            <path
              id="Path_457"
              data-name="Path 457"
              d="M954.961,3399.241l-5.566-.759a1.174,1.174,0,1,1,.316-2.327l5.568.759a1.174,1.174,0,0,1-.318,2.327"
              transform="translate(-858.05 -3072.677)"
              fill="#fff"
            />
            <path
              id="Path_458"
              data-name="Path 458"
              d="M1815.257,3991.755a1.175,1.175,0,0,1-.369-1.62,10.2,10.2,0,0,0,1.547-5.682,1.174,1.174,0,1,1,2.347-.071,12.574,12.574,0,0,1-1.906,7,1.174,1.174,0,0,1-1.619.368"
              transform="translate(-1641.865 -3603.857)"
              fill="#fff"
            />
            <path
              id="Path_459"
              data-name="Path 459"
              d="M846.374,296.79a1.174,1.174,0,0,1-1.174-1.17l-.115-33.936a8.312,8.312,0,0,0-7.457-8.242L709.576,240.068a1.174,1.174,0,0,1-1.046-1.29,1.163,1.163,0,0,1,1.29-1.046l128.053,13.374a10.658,10.658,0,0,1,9.559,10.569l.115,33.936a1.174,1.174,0,0,1-1.17,1.178Z"
              transform="translate(-641.04 -215.082)"
              fill="#fff"
            />
            <path
              id="Path_460"
              data-name="Path 460"
              d="M525.491,365.207l-.427-125.025a10.674,10.674,0,0,1,11.78-10.642l.668.072a1.174,1.174,0,0,1-.252,2.335l-.665-.072a8.324,8.324,0,0,0-9.183,8.3l.426,125.025a8.329,8.329,0,0,0,9.245,8.236l61.874-6.9a1.174,1.174,0,1,1,.26,2.334l-61.875,6.9a10.68,10.68,0,0,1-11.851-10.562"
              transform="translate(-475.054 -207.624)"
              fill="#fff"
            />
            <path
              id="Path_461"
              data-name="Path 461"
              d="M2057.082,981.728a1.175,1.175,0,0,1,1.039-1.3,8.293,8.293,0,0,0,7.4-8.289l-.13-53.966a1.174,1.174,0,0,1,1.171-1.177h0a1.174,1.174,0,0,1,1.174,1.172l.13,53.965a10.636,10.636,0,0,1-9.49,10.631,1.175,1.175,0,0,1-1.3-1.039"
              transform="translate(-1861.148 -829.66)"
              fill="#fff"
            />
            <path
              id="Path_462"
              data-name="Path 462"
              d="M1365.121,1613.481a1.174,1.174,0,0,1,1.038-1.3l45.607-5.038a1.174,1.174,0,1,1,.259,2.334l-45.608,5.038a1.174,1.174,0,0,1-1.3-1.038"
              transform="translate(-1235.093 -1454.067)"
              fill="#fff"
            />
            <path
              id="Path_463"
              data-name="Path 463"
              d="M776.972,499.9c-44.3-4.957-97.452-9.4-141.737-14.352a1.174,1.174,0,0,1,.261-2.334c44.269,4.953,97.441,9.4,141.737,14.352a1.174,1.174,0,0,1-.261,2.334"
              transform="translate(-573.787 -437.184)"
              fill="#fff"
            />
            <path
              id="Path_464"
              data-name="Path 464"
              d="M1961.617,478.039a2.775,2.775,0,1,0,1.4.121"
              transform="translate(-1772.739 -432.508)"
              fill="#fff"
            />
            <path
              id="Path_465"
              data-name="Path 465"
              d="M1950.553,473.459a3.744,3.744,0,0,1-3.154-5.484,3.911,3.911,0,0,1,2.825-2.249,2.662,2.662,0,0,0,1.988.172,4.083,4.083,0,0,1,1.707,6.25,4.039,4.039,0,0,1-3.365,1.311m.485-5.577a1.166,1.166,0,0,1-.42.158,1.6,1.6,0,0,0-1.095.935c-1.229,2.608,3.577,3.03,2.818.245a1.6,1.6,0,0,0-.917-1.111,1.169,1.169,0,0,1-.386-.228"
              transform="translate(-1761.543 -421.351)"
              fill="#fff"
            />
            <path
              id="Path_466"
              data-name="Path 466"
              d="M1807.587,461.456a2.775,2.775,0,1,0,1.4.121"
              transform="translate(-1633.38 -417.504)"
              fill="#fff"
            />
            <path
              id="Path_467"
              data-name="Path 467"
              d="M1793.475,455a4.083,4.083,0,0,1,2.76-5.862,2.659,2.659,0,0,0,1.988.173,3.949,3.949,0,1,1-4.747,5.689m3.574-3.7a1.158,1.158,0,0,1-.42.158,1.6,1.6,0,0,0-1.095.936c-1.227,2.61,3.576,3.024,2.818.245a1.6,1.6,0,0,0-.917-1.111,1.171,1.171,0,0,1-.386-.228"
              transform="translate(-1622.225 -406.348)"
              fill="#fff"
            />
            <path
              id="Path_468"
              data-name="Path 468"
              d="M1660.377,438.007a2.775,2.775,0,1,0,1.4.121"
              transform="translate(-1500.191 -396.289)"
              fill="#fff"
            />
            <path
              id="Path_469"
              data-name="Path 469"
              d="M1649.309,433.426a3.745,3.745,0,0,1-3.156-5.483,3.913,3.913,0,0,1,2.825-2.251,2.661,2.661,0,0,0,1.988.173,4.084,4.084,0,0,1,1.708,6.25,4.025,4.025,0,0,1-3.366,1.311m.484-5.577a1.169,1.169,0,0,1-.42.158c-1.36.232-2.063,2.889.139,3.079s1.974-2.544.667-3.009a1.18,1.18,0,0,1-.387-.228"
              transform="translate(-1488.991 -385.132)"
              fill="#fff"
            />
            <path
              id="Path_470"
              data-name="Path 470"
              d="M472.72,231.551c-1.886,4.068-1.918,8.717-1.916,13.2q.025,49.869.051,99.738c0,5.45.04,11.076,2.2,16.079s7.026,9.261,12.467,8.949c-3.34-.631-5.879-3.546-7.032-6.744s-1.166-6.677-1.165-10.077q.005-55.854.01-111.707c0-3.5.021-7.1,1.378-10.327,1.339-3.183,4.205-5.789,7.765-5.952-4.694-2.951-11.849,2.725-13.756,6.839"
              transform="translate(-425.962 -202.569)"
              fill="#fff"
            />
            <path
              id="Path_471"
              data-name="Path 471"
              d="M247.883,44.226c-.03,0-.059,0-.089,0a1.174,1.174,0,0,1-1.084-1.258c1.49-20.049,16.736-38.1,36.251-42.932a1.174,1.174,0,1,1,.564,2.28C264.967,6.9,250.47,24.074,249.053,43.139a1.174,1.174,0,0,1-1.17,1.087"
              transform="translate(-223.21 0.001)"
              fill="#fff"
            />
            <path
              id="Path_472"
              data-name="Path 472"
              d="M264.687,3565.041a11.279,11.279,0,0,1-9.565-12.794,3.314,3.314,0,0,1,.973-2.211c1.026-.807,2.792-.373,3.954.279,3.3,1.842,4.22,5.649,5.184,9.214.389,1.441,1.124,2.819,1.494,4.237.331,1.274-.731,1.458-2.041,1.275"
              transform="translate(-230.717 -3211.514)"
              fill="#ccc"
            />
            <path
              id="Path_473"
              data-name="Path 473"
              d="M577.554,3433.128a29.823,29.823,0,0,0,.851-4.971c1.661-14.627,1.539-14.446,2.061-15.543.934-1.962,3.056-2.388,4.855-.078,2.847,3.656,2.947,9.834-2.189,15.639a38.327,38.327,0,0,1-6.5,5.566Z"
              transform="translate(-521.713 -3086.089)"
              fill="#ccc"
            />
            <path
              id="Path_474"
              data-name="Path 474"
              d="M420.535,3472.824c-1.172-5.876,1.6-11.791,5.3-16.472,3.538-4.48,8.049-8.352,11.082-13.039,2.669-4.123,4.558-10.247,1.858-15.069a1.174,1.174,0,0,1,2.049-1.148c3.211,5.734,1.106,12.8-1.935,17.493-3.218,4.973-7.643,8.7-11.211,13.218-3.255,4.121-5.862,9.425-4.84,14.557a1.174,1.174,0,1,1-2.3.459"
              transform="translate(-380.235 -3100.137)"
              fill="#fff"
            />
            <path
              id="Path_475"
              data-name="Path 475"
              d="M494.9,3343.588a1.174,1.174,0,0,1-.183-1.65c1.965-2.457,5.351-3.134,7.687-4.163,2.375-1.044,4.826-1.734,6.661-3.219,4.365-3.535,6.34-13.66.914-22.524a4.185,4.185,0,0,0-5.7-1.454c-1.873,1.088-4.859,4.65-8.041,7.5a13,13,0,0,0-2.435,2.58,11.959,11.959,0,0,0,2.989,16,1.174,1.174,0,1,1-1.388,1.894,14.309,14.309,0,0,1-3.608-19.113c1.011-1.666,2.8-2.96,4.026-4.173,5-4.96,5.775-5.843,7.279-6.716a6.52,6.52,0,0,1,8.884,2.258,24.184,24.184,0,0,1,3.266,16.788c-.495,2.671-1.713,6.365-4.705,8.787-2.17,1.757-4.847,2.51-7.192,3.543s-5.327,1.64-6.8,3.48a1.174,1.174,0,0,1-1.65.183"
              transform="translate(-442.985 -2992.611)"
              fill="#fff"
            />
            <path
              id="Path_476"
              data-name="Path 476"
              d="M336.364,3653.2a1.174,1.174,0,0,1-1.039-1.3,14.684,14.684,0,0,0-2.874-10.636c-3.671-4.874-11.034-7.72-12.364-14.733a1.174,1.174,0,1,1,2.307-.437c.695,3.667,3.951,6.4,6.732,8.707a31.011,31.011,0,0,1,5.2,5.051,17,17,0,0,1,3.332,12.307,1.174,1.174,0,0,1-1.3,1.038"
              transform="translate(-289.582 -3279.859)"
              fill="#fff"
            />
            <path
              id="Path_477"
              data-name="Path 477"
              d="M226.989,3495.215a16.406,16.406,0,0,1-13.545-16.414c.012-2.817.853-5.127,1.619-7.8a4.772,4.772,0,0,1,7.35-2.584c2.224,1.59,4.686,3.1,6.564,5.259a14.65,14.65,0,0,1,1.714,16.278,1.175,1.175,0,0,1-2.053-1.141,12.232,12.232,0,0,0-1.431-13.594c-1.662-1.91-4-3.352-6.159-4.892a2.421,2.421,0,0,0-3.728,1.325c-.722,2.517-1.514,4.681-1.527,7.168A14.054,14.054,0,0,0,227.4,3492.9a1.174,1.174,0,1,1-.408,2.312"
              transform="translate(-193.112 -3137.261)"
              fill="#fff"
            />
            <path
              id="Path_478"
              data-name="Path 478"
              d="M458.651,3753.56c1.762-.437,3.293-1.393,5.033-1.849a10.29,10.29,0,0,0,1.8-2.881,1.174,1.174,0,1,0-2.174-.887c-2.881,7.057-13.834,6.523-17.848,14.281a1.174,1.174,0,1,0,2.085,1.079c1.976-3.817,6.1-5.217,10.335-7.374a1.473,1.473,0,0,1,.763-2.369"
              transform="translate(-402.918 -3390.306)"
              fill="#fff"
            />
            <path
              id="Path_479"
              data-name="Path 479"
              d="M559.091,3684.729c-.355-4.456-5.259-9.346-12.261-4.332a20.654,20.654,0,0,0-7.221,9.4,3.621,3.621,0,0,1-.388.643,3.609,3.609,0,0,0-.824,2.066c0,2.141,1.195,2.936,2.33,2.676,5.168.5,10.784.654,14.974-2.888a9.235,9.235,0,0,0,3.39-7.56m-3.075,3.117h-.087a.694.694,0,0,1-.078-.016,2.888,2.888,0,0,1-2.187,2.768,5.927,5.927,0,0,1-.668.1c-1.146.131-.527.154-.989,1.1a2.32,2.32,0,0,1-2.321,1.128c-.409,0-.118,0-5.668-.424a.783.783,0,0,1,.122-1.561l5.178.4c1.04.082,1.243-.155,1.279-.229a4.254,4.254,0,0,1,.357-1.083c.592-.941,1.743-.826,2.353-.968a1.329,1.329,0,0,0,.981-1.255.782.782,0,0,1,.771-.794h.012a.724.724,0,0,1,.08.016.78.78,0,0,1,.778-.76h.087a.783.783,0,1,1,0,1.565"
              transform="translate(-487.117 -3327.94)"
              fill="#fff"
            />
            <path
              id="Path_480"
              data-name="Path 480"
              d="M130.1,4128.754H68.49a1.174,1.174,0,1,1,0-2.348H130.1a1.174,1.174,0,1,1,0,2.348"
              transform="translate(-60.904 -3733.384)"
              fill="#fff"
            />
            <path
              id="Path_481"
              data-name="Path 481"
              d="M324.362,3890.492a1.175,1.175,0,0,0-.819-.332H301.9a1.176,1.176,0,0,0-.819.332,16.565,16.565,0,0,0-5.06,11.965v.487c0,10.4,9.53,11.6,16.7,11.6s16.7-1.2,16.7-11.6v-.487a16.568,16.568,0,0,0-5.06-11.965m-5.687,18.471a.783.783,0,0,1-1.188-1.021,12.117,12.117,0,0,0,2.9-7.3.783.783,0,0,1,1.564.073,13.7,13.7,0,0,1-3.277,8.25m3.172-10.856-.108.108a.783.783,0,0,1-1.107-1.107l.108-.108a.783.783,0,0,1,1.107,1.107"
              transform="translate(-267.827 -3519.64)"
              fill="#fff"
            />
            <path
              id="Path_482"
              data-name="Path 482"
              d="M2459.549,3933.646h-25.144a1.174,1.174,0,1,1,0-2.348h25.144a1.174,1.174,0,0,1,0,2.348"
              transform="translate(-2201.477 -3556.86)"
              fill="#fff"
            />
            <path
              id="Path_483"
              data-name="Path 483"
              d="M3443.868,3933.646H3426.5a1.174,1.174,0,1,1,0-2.348h17.371a1.174,1.174,0,1,1,0,2.348"
              transform="translate(-3099.076 -3556.86)"
              fill="#fff"
            />
            <path
              id="Path_484"
              data-name="Path 484"
              d="M680.16,700.4h0c-16.166-5.527-30.521-7.071-46.544-5.006a4.135,4.135,0,0,1-3.768-1.612,16.355,16.355,0,0,1-2.3-15.443,4.481,4.481,0,0,1,2.676-2.648c16.111-5.474,27.8-14.233,42.676-23.879a2.3,2.3,0,0,1,3.437,1.469c4.285,13.9,6.97,31.435,6.843,44.678a2.363,2.363,0,0,1-3.014,2.442m.76-2.222h0Zm0,0Zm-.129-.044a.55.55,0,0,0,.035-.2c.126-13.021-2.52-30.278-6.739-43.964a.511.511,0,0,0-.044-.106c-14.456,9.38-26.75,18.5-43.068,24.048a2.142,2.142,0,0,0-1.247,1.287,14.012,14.012,0,0,0,1.964,13.126,1.785,1.785,0,0,0,1.624.738c16.1-2.074,30.749-.631,47.474,5.07"
              transform="translate(-566.872 -589.41)"
              fill="#fff"
            />
            <path
              id="Path_485"
              data-name="Path 485"
              d="M737.97,1112.051a3.193,3.193,0,0,1-1.967-1.662c-4.283-8.356-8.022-15.966-10.406-24.514a1.175,1.175,0,0,1,2.263-.631c2.324,8.336,6.009,15.833,10.232,24.071a.871.871,0,0,0,.483.466c.27.076-.074,0,2.544-1.248a18.372,18.372,0,0,0,1.977-1.032c.586-.388.493-1.642.021-1.847a1.175,1.175,0,0,1-.255-2c.365-.288.521-1.588.153-3.265a2.579,2.579,0,0,0-1.852-1.891,1.175,1.175,0,0,1-.641-1.875c.044-.055.4-.6-.367-2.874a2.486,2.486,0,0,0-1.721-1.581,1.175,1.175,0,0,1-.846-1.47c.345-1.18-.64-3.559-1.716-4.82a1.174,1.174,0,1,1,1.786-1.525,10.031,10.031,0,0,1,2.344,5.91A4.83,4.83,0,0,1,742.38,1093a8.283,8.283,0,0,1,.56,3.709,4.944,4.944,0,0,1,2.369,3.181,7.886,7.886,0,0,1-.143,4.5,3.662,3.662,0,0,1-.776,5.075c-1.183.78-3.245,1.567-4.259,2.237a3.1,3.1,0,0,1-2.161.353"
              transform="translate(-656.449 -980.705)"
              fill="#fff"
            />
            <path
              id="Path_486"
              data-name="Path 486"
              d="M741.239,897.423a32.753,32.753,0,0,1-3.367-19.648,1.174,1.174,0,0,1,2.325.332,30.356,30.356,0,0,0,3.113,18.211,1.174,1.174,0,0,1-2.072,1.106"
              transform="translate(-667.306 -793.258)"
              fill="#fff"
            />
            <path
              id="Path_487"
              data-name="Path 487"
              d="M1111.63,648.229c3.844,14.39,6.762,29.723,6.829,43.623.005,1.075.053,2.451.877,2.817.808.358,1.549-.64,1.866-1.6a17.535,17.535,0,0,0,.531-6.152c-.537-12.7.474-15.46-6.347-38.907a7.756,7.756,0,0,0-1.173-2.672c-.547-.686-1.714-1.3-2.432-.8-1.049.736-.443,2.6-.152,3.691"
              transform="translate(-1005.354 -582.981)"
              fill="#fff"
            />
            <path
              id="Path_488"
              data-name="Path 488"
              d="M1107.774,684.6c-1.56-.691-1.57-2.769-1.576-3.885-.058-12.1-2.342-26.68-6.789-43.325h0c-.485-1.772-.977-3.84.612-4.955,1.271-.892,3.078-.161,4.024,1.026a8.768,8.768,0,0,1,1.382,3.075c6.917,23.777,5.871,26.952,6.393,39.185.116,2.753.121,7.757-2.4,8.875a2.6,2.6,0,0,1-1.646,0m-6.1-47.816c8.121,30.4,6.458,43.382,6.992,45.447.948-1.343.874-4.786.8-6.41-.528-12.5.5-15.244-6.3-38.629a6.8,6.8,0,0,0-.963-2.267,1.465,1.465,0,0,0-.9-.538c-.047.049-.164.436.3,2.147Z"
              transform="translate(-994.268 -571.837)"
              fill="#fff"
            />
            <path
              id="Path_489"
              data-name="Path 489"
              d="M1068.455,809.8h-.009a1.175,1.175,0,0,1-1.165-1.183,32.255,32.255,0,0,0-2.8-13.466,1.174,1.174,0,1,1,2.139-.968,34.613,34.613,0,0,1,3.011,14.452,1.174,1.174,0,0,1-1.174,1.165"
              transform="translate(-962.997 -717.918)"
              fill="#fff"
            />
            <path
              id="Path_490"
              data-name="Path 490"
              d="M1250,748.852a1.175,1.175,0,0,1-.13-1.656l5.792-6.778a1.174,1.174,0,0,1,1.786,1.526l-5.792,6.778a1.175,1.175,0,0,1-1.656.13"
              transform="translate(-1130.569 -669.524)"
              fill="#fff"
            />
            <path
              id="Path_491"
              data-name="Path 491"
              d="M1279.7,872.469a1.174,1.174,0,0,1,1.079-1.263l9.594-.753a1.174,1.174,0,0,1,.183,2.341l-9.594.753a1.174,1.174,0,0,1-1.262-1.079"
              transform="translate(-1157.81 -787.543)"
              fill="#fff"
            />
            <path
              id="Path_492"
              data-name="Path 492"
              d="M1287.968,972.357l-6.027-5.151a1.174,1.174,0,1,1,1.526-1.785l6.027,5.151a1.174,1.174,0,1,1-1.526,1.785"
              transform="translate(-1159.47 -873.214)"
              fill="#fff"
            />
            <path
              id="Path_493"
              data-name="Path 493"
              d="M797.649,1144.549a1.174,1.174,0,0,1,0-1.66l30.666-30.666a1.174,1.174,0,0,1,1.66,0l18.256,18.256,30.857-30.858a1.209,1.209,0,0,1,1.66,0l7.818,7.818,15.149-15.149a1.174,1.174,0,0,1,1.66,1.66L889.4,1109.93a1.209,1.209,0,0,1-1.66,0l-7.818-7.818-30.857,30.857a1.209,1.209,0,0,1-1.66,0l-18.256-18.256-29.836,29.836a1.174,1.174,0,0,1-1.661,0"
              transform="translate(-721.365 -987.944)"
              fill="#fff"
            />
            <path
              id="Path_494"
              data-name="Path 494"
              d="M1879.5,1038.141l-9.076-11.753c4.509-.135,8.894-1.152,13.375-1.668a104.882,104.882,0,0,0-4.3,13.422"
              transform="translate(-1692.278 -927.12)"
              fill="#fff"
            />
            <path
              id="Path_495"
              data-name="Path 495"
              d="M1867.421,1027.707l-9.077-11.753a1.174,1.174,0,0,1,.895-1.892,67.354,67.354,0,0,0,8.361-.958c1.6-.252,3.259-.512,4.915-.7a1.174,1.174,0,0,1,1.233,1.581,102.888,102.888,0,0,0-4.251,13.263,1.174,1.174,0,0,1-2.076.462m-5.86-11.431,6.233,8.072c.831-3.088,1.933-6.273,3.074-9.365-3.1.439-6.147,1.021-9.307,1.293"
              transform="translate(-1681.125 -915.968)"
              fill="#fff"
            />
            <line
              id="Line_41"
              data-name="Line 41"
              y2="13.221"
              transform="translate(90.613 144.462)"
              fill="#fff"
            />
            <path
              id="Path_496"
              data-name="Path 496"
              d="M940.205,1519.974a1.174,1.174,0,0,1-1.174-1.174v-13.221a1.174,1.174,0,0,1,2.348,0V1518.8a1.174,1.174,0,0,1-1.174,1.174"
              transform="translate(-849.593 -1361.116)"
              fill="#fff"
            />
            <line
              id="Line_42"
              data-name="Line 42"
              y2="11.083"
              transform="translate(126.61 144.976)"
              fill="#fff"
            />
            <path
              id="Path_497"
              data-name="Path 497"
              d="M1318.15,1523.236a1.174,1.174,0,0,1-1.174-1.174v-11.083a1.174,1.174,0,1,1,2.348,0v11.083a1.174,1.174,0,0,1-1.174,1.174"
              transform="translate(-1191.54 -1366.003)"
              fill="#fff"
            />
            <line
              id="Line_43"
              data-name="Line 43"
              y2="26.238"
              transform="translate(166.721 121.578)"
              fill="#fff"
            />
            <path
              id="Path_498"
              data-name="Path 498"
              d="M1739.275,1292.73a1.174,1.174,0,0,1-1.174-1.174v-26.238a1.174,1.174,0,1,1,2.348,0v26.238a1.174,1.174,0,0,1-1.174,1.174"
              transform="translate(-1572.555 -1143.74)"
              fill="#fff"
            />
            <path
              id="Path_499"
              data-name="Path 499"
              d="M1519.038,694.225l-52.257-2.773a1.082,1.082,0,0,1-1.011-1.148l.318-5.025a1.082,1.082,0,0,1,1.148-1.011l52.258,2.773a1.082,1.082,0,0,1,1.012,1.148l-.318,5.025a1.082,1.082,0,0,1-1.148,1.011"
              transform="translate(-1326.159 -619.092)"
              fill="#fff"
            />
            <path
              id="Path_500"
              data-name="Path 500"
              d="M1594.371,844.289h-.023c-12-.223-24.389-.6-41.423-1.251a1.174,1.174,0,0,1-1.128-1.219,1.187,1.187,0,0,1,1.219-1.128c17.018.653,29.392,1.027,41.377,1.25a1.174,1.174,0,0,1-.021,2.348"
              transform="translate(-1403.995 -760.619)"
              fill="#fff"
            />
            <path
              id="Path_501"
              data-name="Path 501"
              d="M1627.044,951.613h-.021l-20.058-.352a1.174,1.174,0,0,1,.02-2.348h.021l20.058.352a1.174,1.174,0,0,1-.02,2.348"
              transform="translate(-1452.865 -858.532)"
              fill="#fff"
            />
            <path
              id="Path_502"
              data-name="Path 502"
              d="M2633.3,880.07l1.237,5.314c7.565-5.458,17.569-6.286,26.84-5.247,3.951.442,7.9,1.188,11.872.972s4.222-1.534,6.827-4.538,3.18-7.931.546-10.909c.564,4.042-2.434,7.944-6.169,9.591s-4.151,1.481-8.191.9-8.064-1.545-12.143-1.4c-7.074.256-14.241,3.95-21.177,1.63-3.285-1.062-6.282-3.909-6.667-7.458.319,2.565-.822,4.222.583,6.662a9.4,9.4,0,0,0,6.442,4.48"
              transform="translate(-2376.008 -783.211)"
              fill="#ccc"
            />
            <path
              id="Path_503"
              data-name="Path 503"
              d="M2743.86,626.346l-36.453,1.757a1.425,1.425,0,0,0-1.415,1.324v18.685a1.425,1.425,0,0,0,1.415,1.324l36.453,1.757c1,.051,2.11-.5,2.11-1.432V627.778a1.208,1.208,0,0,0-.4-.873,2.352,2.352,0,0,0-1.709-.559m-11.1,14.495c-3.187,1.741-7.028,4.014-11.5,6.249a.784.784,0,0,1-1.133-.7V632.212a.783.783,0,0,1,1.228-.644l11.474,7.942a.782.782,0,0,1-.07,1.33"
              transform="translate(-2448.259 -566.686)"
              fill="#fff"
            />
            <path
              id="Path_504"
              data-name="Path 504"
              d="M2655.537,521.548l-41.062,2.6a8.555,8.555,0,0,0-7.807,8.607l.1,24.271a8.3,8.3,0,0,0,7.68,8.4l1.595.088.04,8.847a1.175,1.175,0,0,0,2,.826l9.084-9.051,26.946,1.514a1.738,1.738,0,0,0,1.225.272,9.022,9.022,0,0,0,9.227-8.581c.3-9.244.347-19.449.636-28.8a8.877,8.877,0,0,0-9.662-8.988m2.135,33.383c0,1.826-1.772,3.092-3.751,3l-36.453-1.757a3,3,0,0,1-2.9-2.888V534.6a3,3,0,0,1,2.9-2.888l36.453-1.757c1.975-.093,3.751,1.169,3.751,3Z"
              transform="translate(-2358.395 -471.857)"
              fill="#fff"
            />
            <path
              id="Path_505"
              data-name="Path 505"
              d="M777.141,378.893c-23.639-3.307-47.715-5.542-71.561-6.644a1.174,1.174,0,0,1,.109-2.346c23.917,1.105,48.067,3.347,71.778,6.665a1.174,1.174,0,0,1-.326,2.326"
              transform="translate(-637.363 -334.669)"
              fill="#fff"
            />
            <path
              id="Path_506"
              data-name="Path 506"
              d="M206.145,49.919a1.174,1.174,0,0,1-.842-1.431,28.653,28.653,0,0,1,15.132-18.405,1.174,1.174,0,1,1,1.018,2.116,26.279,26.279,0,0,0-13.877,16.879,1.174,1.174,0,0,1-1.432.841"
              transform="translate(-185.715 -27.112)"
              fill="#fff"
            />
            <path
              id="Path_507"
              data-name="Path 507"
              d="M75.346,2395.672a1.171,1.171,0,0,1-.54-.132,30.473,30.473,0,0,1-16.18-25.72,1.174,1.174,0,1,1,2.347-.073,28.472,28.472,0,0,0,14.915,23.709,1.174,1.174,0,0,1-.542,2.217"
              transform="translate(-53.042 -2143.009)"
              fill="#fff"
            />
            <path
              id="Path_508"
              data-name="Path 508"
              d="M7.076,2534.641a1.174,1.174,0,0,1-.611-.171,15.189,15.189,0,0,1-6.4-8.047,1.174,1.174,0,1,1,2.228-.742,12.808,12.808,0,0,0,5.4,6.784,1.174,1.174,0,0,1-.612,2.177"
              transform="translate(0 -2284.394)"
              fill="#fff"
            />
            <path
              id="Path_509"
              data-name="Path 509"
              d="M1150.112,4142.143h-30.931a1.174,1.174,0,1,1,0-2.349h30.931a1.174,1.174,0,0,1,0,2.349"
              transform="translate(-1011.522 -3745.497)"
              fill="#fff"
            />
            <path
              id="Path_510"
              data-name="Path 510"
              d="M1975.7,4142.143h-46.97a1.174,1.174,0,0,1,0-2.349h46.97a1.174,1.174,0,0,1,0,2.349"
              transform="translate(-1743.968 -3745.497)"
              fill="#fff"
            />
            <path
              id="Path_511"
              data-name="Path 511"
              d="M606.657,987.4a5.956,5.956,0,0,1-1.167-2.282,10.864,10.864,0,0,1-.72-3.508,4.448,4.448,0,0,1,1.867-3.754,1.174,1.174,0,1,1,1.28,1.969,2.192,2.192,0,0,0-.8,1.809,8.989,8.989,0,0,0,.6,2.751,4.081,4.081,0,0,0,.667,1.427.649.649,0,0,0,.506.243,1.174,1.174,0,1,1,1.015,2.117,2.838,2.838,0,0,1-3.252-.772"
              transform="translate(-547.168 -884.545)"
              fill="#fff"
            />
            <path
              id="Path_512"
              data-name="Path 512"
              d="M1202.5,835.343a8.35,8.35,0,0,1,1.224,14.039"
              transform="translate(-1087.969 -755.78)"
              fill="#fff"
            />
            <path
              id="Path_513"
              data-name="Path 513"
              d="M1191.634,838.942a1.175,1.175,0,0,1,.219-1.646,7.174,7.174,0,0,0-1.052-12.064,1.174,1.174,0,1,1,1.083-2.084,9.523,9.523,0,0,1,1.4,16.014,1.175,1.175,0,0,1-1.647-.219"
              transform="translate(-1076.809 -744.627)"
              fill="#fff"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}
