import React from "react";

export default function Development() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="306.167"
        height="389.136"
        viewBox="0 0 306.167 389.136"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_134"
              data-name="Rectangle 134"
              width="306.167"
              height="389.136"
              transform="translate(0 0)"
              fill="none"
            />
          </clipPath>
        </defs>
        <g id="Group_108" data-name="Group 108" transform="translate(0 -0.002)">
          <g
            id="Group_107"
            data-name="Group 107"
            transform="translate(0 0.002)"
            clip-path="url(#clip-path)"
          >
            <path
              id="Path_290"
              data-name="Path 290"
              d="M50.634,626.5l-.253-.505c.4,2.849,2.977,5.084,5.62,6.218a24.463,24.463,0,0,0,8.455,1.466c14.66.748,29.356.859,44.019.179,3.229-.15,6.67-.413,9.242-2.372,2.645-2.015,3.829-5.5,3.846-8.826a32.808,32.808,0,0,0-1.955-9.738,24.442,24.442,0,0,1,.68,7.021,8.251,8.251,0,0,1-3.189,6.071,12.032,12.032,0,0,1-5.785,1.582,298.679,298.679,0,0,1-52.368.358,14.558,14.558,0,0,1-5.984-1.38c-1.8-1-3.205-3.025-2.84-5.056-.222,2.085.734,2.9.512,4.982"
              transform="translate(-44.403 -543.613)"
              fill="#ccc"
            />
            <path
              id="Path_291"
              data-name="Path 291"
              d="M713.022,1372.17l76.049-.582a7.219,7.219,0,0,0,3.943-.788c1.139-.743,1.741-2.471.847-3.5a3.642,3.642,0,0,0-2.779-.838c-23.3-.362-48.63-.518-73.927-.419-2.948.011-7.765-.883-10.552.094-1.573.551-3.738,2.353-2.853,4.313,1.025,2.271,7.266,1.732,9.274,1.717"
              transform="translate(-623.991 -1211.261)"
              fill="#ccc"
            />
            <path
              id="Path_292"
              data-name="Path 292"
              d="M1914.827,1185.305q26.913.691,53.839.452a7.153,7.153,0,0,0,3.569-.655,2.466,2.466,0,0,0,1.168-3.1c-.616-1.12-2.147-1.247-3.425-1.235l-34.854.323-17.012.158c-1.645.015-5.909-.636-6.7,1.532-.761,2.088,1.914,2.527,3.415,2.527"
              transform="translate(-1695.163 -1047.252)"
              fill="#ccc"
            />
            <path
              id="Path_293"
              data-name="Path 293"
              d="M1751.572,2157.521c-18.289-.311-38.309-3.273-55.083,2.006-1.412.444-1.407,2.664-.295,3.641s2.729,1.051,4.209,1.075c65.2,1.035,81.472,1.6,101.5.276a10.48,10.48,0,0,0,5.308-1.334c1.526-1.041,2.41-3.262,1.383-4.8-.946-1.414-2.916-1.6-4.617-1.633-31.942-.675-57.68,1.293-101.6,4.3"
              transform="translate(-1503.688 -1912.58)"
              fill="#ccc"
            />
            <path
              id="Path_294"
              data-name="Path 294"
              d="M864.96,2673.673a30.436,30.436,0,0,0,13.389,22.3c11.069,7.273,25.334,6.46,38.578,6.465,25.591.011,51.5,4.045,76.58-1.039,7.712-1.563,15.41-4.061,21.682-8.812s10.968-12.047,11.115-19.914c-6.358,10.811-19.589,17.7-31.585,21.357s-23.836,2.612-36.357,1.9-25.912-1.052-38.452-1.294c-7.6-.147-15.219.24-22.783-.479s-15.2-2.628-21.455-6.938-10.972-11.31-11.175-18.906"
              transform="translate(-766.745 -2366.601)"
              fill="#ccc"
            />
            <path
              id="Path_295"
              data-name="Path 295"
              d="M1858.4,3394.5c5.691-.19,11.361-1.165,17.054-1.166,8.352,0,16.652,2.094,25,1.944a4.354,4.354,0,0,0,2.51-.594,3.02,3.02,0,0,0,1.187-2.807c-.267-1.752-1.943-2.2-3.267-2.251-14.8-.594-29.714.82-44.453.488-2.488-.056-4.976-.16-7.462-.333-1.275-.089-4.246-1.376-4.137,1.361.169,4.235,11.374,3.43,13.565,3.357"
              transform="translate(-1636.226 -3006.154)"
              fill="#ccc"
            />
            <path
              id="Path_296"
              data-name="Path 296"
              d="M1837.12,151.254l-11.785,4.427a4.941,4.941,0,0,1,.612,1.34c.691,2.513-.853,5.88-3.867,6.286-2.97,1.551-5.018,1.669-7.015,3.18-2.6,1.969-7.473-.179-7.587-4.1l-7.525,2.827a22.267,22.267,0,0,0,15.9,12.6,20.275,20.275,0,0,0,18.945-6.765,18.623,18.623,0,0,0,2.321-19.795"
              transform="translate(-1596.426 -134.151)"
              fill="#ccc"
            />
            <path
              id="Path_297"
              data-name="Path 297"
              d="M1798.44.076A20.856,20.856,0,1,0,1817.5,22.59,20.884,20.884,0,0,0,1798.44.076m9.332,16.375L1793.6,28.444a1.516,1.516,0,0,1-2.094-.129c-1.916-2.078-4.183-3.942-6.191-6.117a1.516,1.516,0,1,1,2.229-2.056c1.6,1.732,3.468,3.342,5.184,5.069l13.084-11.074a1.516,1.516,0,0,1,1.958,2.315"
              transform="translate(-1575.051 -0.002)"
              fill="#fff"
            />
            <path
              id="Path_298"
              data-name="Path 298"
              d="M854.731,1546.223a1.516,1.516,0,0,1-1.516-1.512l-.324-111.477a1.516,1.516,0,0,1,1.512-1.521h0a1.516,1.516,0,0,1,1.516,1.511l.324,111.478a1.516,1.516,0,0,1-1.512,1.52Z"
              transform="translate(-756.452 -1269.824)"
              fill="#fff"
            />
            <path
              id="Path_299"
              data-name="Path 299"
              d="M855.754,1270.112v4.834a1.516,1.516,0,0,0,3.032,0v-4.763q-1.524-.073-3.032-.071"
              transform="translate(-758.991 -1126.496)"
              fill="#fff"
            />
            <path
              id="Path_300"
              data-name="Path 300"
              d="M898.929,563.159c-10.338.5-24.152,1.875-31.254,5.556-8.876,4.614-11.049,14.653-11.912,21.8a1.583,1.583,0,0,0-.011.182v42.287q1.508-.006,3.032.055v-42.25c1.249-10.225,4.519-16.387,10.287-19.385,5.161-2.675,15.817-4.528,30.005-5.219a1.516,1.516,0,0,0,1.44-1.588,1.483,1.483,0,0,0-1.588-1.441"
              transform="translate(-758.99 -499.478)"
              fill="#fff"
            />
            <path
              id="Path_301"
              data-name="Path 301"
              d="M887.012,2580.149l-.09,0c-1.146-.067-2.291-.143-3.436-.219l-.193-.013c-8.616-.569-18.327-1.655-24.528-7.369-5.735-5.292-9.072-13.6-9.156-22.8v-9.807a1.516,1.516,0,0,1,3.032,0v9.794c.076,8.35,3.058,15.858,8.18,20.584,5.473,5.044,14.573,6.038,22.672,6.573l.193.013c1.138.075,2.275.15,3.413.217a1.516,1.516,0,0,1-.088,3.03"
              transform="translate(-753.541 -2251.394)"
              fill="#fff"
            />
            <path
              id="Path_302"
              data-name="Path 302"
              d="M1396.873,585.177h-.036a1.517,1.517,0,0,1-1.481-1.551l.042-1.677q.031-1.179.058-2.4c.011-.3.021-.573.021-.847.011-.574.021-1.1.041-1.617l0-.14c.2-8.676.457-19.473-6.586-25.052a17.6,17.6,0,0,0-7.032-3.211h0c-.047-.012-.095-.025-.141-.04-5.037-1.158-10.843-.972-16.46-.791-.952.031-1.893.061-2.818.084l-.514.011c-.741.01-1.492.03-2.233.05h-.1l-60.708,1.475c-2.177.05-4.4.07-6.637.089-1.377.012-2.763.024-4.153.043a1.516,1.516,0,1,1-.042-3.032c1.395-.019,2.786-.031,4.168-.043,2.227-.019,4.43-.038,6.591-.088l60.743-1.476c.813-.02,1.577-.041,2.342-.051l.505-.01c.88-.023,1.814-.053,2.759-.083,5.847-.19,11.9-.384,17.346.891a1.426,1.426,0,0,1,.158.046,20.48,20.48,0,0,1,8.113,3.762c8.223,6.514,7.951,18.149,7.73,27.5l0,.165c-.021.521-.031,1.026-.041,1.523,0,.31-.01.622-.022.934-.017.8-.038,1.6-.058,2.394l-.042,1.669a1.517,1.517,0,0,1-1.515,1.481"
              transform="translate(-1141.12 -483.089)"
              fill="#fff"
            />
            <path
              id="Path_303"
              data-name="Path 303"
              d="M2233.881,1220.266a1.516,1.516,0,0,1-1.479-1.552c.582-24.108.784-34.453,1-45.4l.073-3.771a1.517,1.517,0,0,1,1.515-1.486h.031a1.516,1.516,0,0,1,1.486,1.545l-.074,3.771c-.214,10.956-.416,21.3-1,45.419a1.517,1.517,0,0,1-1.553,1.479"
              transform="translate(-1979.975 -1035.976)"
              fill="#fff"
            />
            <path
              id="Path_304"
              data-name="Path 304"
              d="M1288.763,2257.2c-14.128,0-28.165-.641-44.942-1.672a1.516,1.516,0,0,1,.186-3.026c28.742,1.766,49.413,2.382,76.29.58,8.429-.546,22.505-2.91,27.621-9.684a14.572,14.572,0,0,0,2.462-5.249c1.026-3.915.876-3.6,1.961-57.32.161-7.858.161-7.858.623-8.3a1.517,1.517,0,0,1,2.558,1.167c-.105,3.633-.592,31.362-1.161,55.33-.121,5.8-.489,11.557-4.026,16.2-5.769,7.638-20.29,10.259-29.839,10.878-11.324.759-21.552,1.092-31.733,1.092"
              transform="translate(-1101.915 -1926.509)"
              fill="#fff"
            />
            <path
              id="Path_305"
              data-name="Path 305"
              d="M1934.886,552.051c1.071-.1,2.2-.222,3.376-.323-.354.081-.7.172-1.041.273-.01-.01-.01-.01-.02,0l-.232.061-3.3.111c.394-.04.788-.081,1.2-.121Z"
              transform="translate(-1715.026 -489.342)"
              fill="#fff"
            />
            <path
              id="Path_306"
              data-name="Path 306"
              d="M2269.077,1181.46c-.283,16.121-.546,31.95-.889,49.183H2258.06q.652-24.516,1.163-49.183"
              transform="translate(-2002.733 -1047.868)"
              fill="#fff"
            />
            <path
              id="Path_307"
              data-name="Path 307"
              d="M2008.146,579.857q.046-2.82.091-5.64a69.312,69.312,0,0,0-2.082-18.223c-1.526-5.711-5.852-10.057-11.755-12.877q-.485-.227-1-.424c-5.216-2.011-12.756-1.607-19.144-1.021,12.21-2.941,31.939-1.021,38.681,7.833,5.751,7.57,5.216,21.044,5.023,30.352-.01.232-.02.455-.02.677"
              transform="translate(-1751.02 -479.286)"
              fill="#fff"
            />
            <path
              id="Path_308"
              data-name="Path 308"
              d="M2049.76,2176.61c-1.755,58.4-1.051,34.771-1.051,47.383a100.782,100.782,0,0,1-.4,15.313,13.161,13.161,0,0,1-.819,3.214v.01c-2.83,7.318-11.856,12.937-20.76,13.786a46.775,46.775,0,0,0,5.023-.394c.01,0,.01,0,.021-.01,9.794-1.3,19.305-5.761,23.641-13.251,5.084-8.763,5.333-55.078,5.525-64.872Z"
              transform="translate(-1797.555 -1930.493)"
              fill="#fff"
            />
            <path
              id="Path_309"
              data-name="Path 309"
              d="M1072,2624.148c-12.752,0-25.255-.149-38.236-.3-9.342-.111-19-.226-28.9-.283a1.516,1.516,0,0,1,.018-3.032c9.9.057,19.569.172,28.917.283,19.608.233,38.128.454,58.04.167a1.551,1.551,0,0,1,1.538,1.495,1.517,1.517,0,0,1-1.495,1.538c-6.757.1-13.354.137-19.884.137"
              transform="translate(-889.903 -2324.217)"
              fill="#fff"
            />
            <path
              id="Path_310"
              data-name="Path 310"
              d="M1826.674,2218.3a1.516,1.516,0,0,1-.029-3.032c10.023-.2,19.317-.519,28.36-.986.414-14.8.707-30.608.92-49.578a1.5,1.5,0,0,1,1.533-1.5,1.517,1.517,0,0,1,1.5,1.533c-.221,19.586-.526,35.8-.961,51.028a1.516,1.516,0,0,1-1.434,1.47c-9.516.51-19.283.857-29.857,1.063h-.03"
              transform="translate(-1618.781 -1918.601)"
              fill="#fff"
            />
            <path
              id="Path_311"
              data-name="Path 311"
              d="M2102.616,1267.164h-.009a1.516,1.516,0,0,1-1.508-1.525c.091-17.08.137-27.715.363-43.98a1.516,1.516,0,0,1,3.032.043c-.226,16.191-.271,26.683-.363,43.954a1.517,1.517,0,0,1-1.516,1.508"
              transform="translate(-1863.521 -1082.197)"
              fill="#fff"
            />
            <path
              id="Path_312"
              data-name="Path 312"
              d="M2111.9,810.085h-.032a1.517,1.517,0,0,1-1.485-1.547c.062-2.934.142-5.869.224-8.8a1.516,1.516,0,0,1,1.514-1.474h.042a1.516,1.516,0,0,1,1.473,1.557c-.08,2.928-.161,5.855-.222,8.783a1.517,1.517,0,0,1-1.515,1.485"
              transform="translate(-1871.756 -707.998)"
              fill="#fff"
            />
            <path
              id="Path_313"
              data-name="Path 313"
              d="M995.468,1286.032q-1.5-.373-3.039-.7c-.028,9.739-.037,21.289-.022,35.008q.033,30.28.414,102.578c.01,1.616.016,2.52.019,2.524a1.516,1.516,0,0,0,1.424,1.6l.09,0a1.516,1.516,0,0,0,1.512-1.427c0-.061,0-.061-.013-2.72-.359-57.3-.484-104.158-.385-136.869"
              transform="translate(-880.186 -1139.996)"
              fill="#fff"
            />
            <path
              id="Path_314"
              data-name="Path 314"
              d="M1101.65,799.184c-35.073.594-71.3,1.135-106.11,1.74h0a1.608,1.608,0,0,0-1.04.423c-.63.589-1.276,1.195-1.484,42.511q1.53.292,3.04.627c.115-22.644.361-36.724.733-40.55,33.773-.585,70.87-1.141,104.913-1.718a1.516,1.516,0,1,0-.052-3.032"
              transform="translate(-880.731 -708.817)"
              fill="#fff"
            />
            <path
              id="Path_315"
              data-name="Path 315"
              d="M1014.608,841.352c.043,0,.084,0,.129,0-.049,0-.087,0-.129,0"
              transform="translate(-899.882 -746.217)"
              fill="#fff"
            />
            <path
              id="Path_316"
              data-name="Path 316"
              d="M1015.753,841.387h0Z"
              transform="translate(-900.897 -746.248)"
              fill="#fff"
            />
            <path
              id="Path_317"
              data-name="Path 317"
              d="M2000.238,799.5a1.516,1.516,0,0,1-.026-3.032l12.843-.222h.028a1.516,1.516,0,0,1,.026,3.032l-12.843.222Z"
              transform="translate(-1772.719 -706.21)"
              fill="#fff"
            />
            <path
              id="Path_318"
              data-name="Path 318"
              d="M1401.707,672.565l-35.455.684a2.06,2.06,0,0,1-2.153-1.953v-.04a2.049,2.049,0,0,1,2.067-2.034l35.457-.684a2.054,2.054,0,0,1,2.153,2.024c0,.013,0,.027,0,.04a2.055,2.055,0,0,1-2.067,1.963"
              transform="translate(-1209.854 -592.943)"
              fill="#fff"
            />
            <path
              id="Path_319"
              data-name="Path 319"
              d="M1354.319,662.867a3.578,3.578,0,0,1-3.625-3.437,3.453,3.453,0,0,1,.968-2.5,3.65,3.65,0,0,1,2.585-1.12l35.456-.684a3.7,3.7,0,0,1,2.679,1.067,3.426,3.426,0,0,1,1.021,2.5,3.568,3.568,0,0,1-3.554,3.493l-35.456.683h-.074m35.5-2.2h0Zm-.043-2.512h-.014l-35.456.684a.647.647,0,0,0-.456.188.417.417,0,0,0-.124.3c.006.3.258.495.608.51l35.455-.683a.551.551,0,0,0,.58-.474c0-.029,0-.1.005-.126a.467.467,0,0,0-.135-.215.656.656,0,0,0-.464-.181"
              transform="translate(-1197.966 -581.045)"
              fill="#fff"
            />
            <path
              id="Path_320"
              data-name="Path 320"
              d="M1776.546,663.731a2.516,2.516,0,0,1,3.411-.17,2.195,2.195,0,0,1,.056,3.227,2.516,2.516,0,0,1-3.411.17,2.195,2.195,0,0,1-.056-3.227"
              transform="translate(-1575.063 -588.009)"
              fill="#fff"
            />
            <path
              id="Path_321"
              data-name="Path 321"
              d="M1766.3,657.227a3.9,3.9,0,0,1-2.591-.958,3.637,3.637,0,0,1-1.246-2.625,3.828,3.828,0,0,1,1.115-2.8,4.055,4.055,0,0,1,5.493-.241,3.637,3.637,0,0,1,1.246,2.625,3.826,3.826,0,0,1-1.115,2.8,4.076,4.076,0,0,1-2.9,1.2m.178-4.564a1.05,1.05,0,0,0-.743.308h0a.778.778,0,0,0-.241.568.624.624,0,0,0,.221.455,1.009,1.009,0,0,0,1.33-.1.777.777,0,0,0,.242-.569.622.622,0,0,0-.221-.455.878.878,0,0,0-.586-.21m-1.822-.758h0Z"
              transform="translate(-1563.169 -576.184)"
              fill="#fff"
            />
            <rect
              id="Rectangle_133"
              data-name="Rectangle 133"
              width="10.113"
              height="28.855"
              transform="matrix(0.005, -1, 1, 0.005, 156.292, 319.822)"
              fill="#fff"
            />
            <path
              id="Path_322"
              data-name="Path 322"
              d="M1399.15,2738.866h-.007l-28.855-.13a1.516,1.516,0,0,1-1.509-1.524l.051-10.113a1.516,1.516,0,0,1,1.516-1.508h.007l28.855.13a1.516,1.516,0,0,1,1.509,1.524l-.051,10.114a1.516,1.516,0,0,1-1.516,1.508m-27.331-3.155,25.823.116.035-7.081-25.823-.116Z"
              transform="translate(-1214.006 -2417.398)"
              fill="#fff"
            />
            <path
              id="Path_323"
              data-name="Path 323"
              d="M1806.333,1674.7H1694.647a1.516,1.516,0,0,1-1.516-1.516v-62.665a1.516,1.516,0,0,1,1.516-1.516h25.036a1.516,1.516,0,0,1,0,3.032h-23.52v59.633h108.654V1653.31a1.516,1.516,0,1,1,3.032,0v19.871a1.516,1.516,0,0,1-1.516,1.516"
              transform="translate(-1501.682 -1427.065)"
              fill="#fff"
            />
            <path
              id="Path_324"
              data-name="Path 324"
              d="M2043.067,1647.58a1.516,1.516,0,0,1-1.516-1.516v-34.031h-79.989a1.516,1.516,0,0,1,0-3.032h81.506a1.516,1.516,0,0,1,1.516,1.516v35.547a1.516,1.516,0,0,1-1.516,1.516"
              transform="translate(-1738.416 -1427.065)"
              fill="#fff"
            />
            <path
              id="Path_325"
              data-name="Path 325"
              d="M1969.279,912.077h-58.845a1.516,1.516,0,0,1-1.516-1.516V877.54a1.516,1.516,0,0,1,1.516-1.516h58.845a1.516,1.516,0,0,1,1.516,1.516v15.07a1.516,1.516,0,1,1-3.032,0V879.056H1911.95v29.989h55.813V898.22a1.516,1.516,0,1,1,3.032,0v12.341a1.516,1.516,0,0,1-1.516,1.516"
              transform="translate(-1693.069 -776.969)"
              fill="#fff"
            />
            <path
              id="Path_326"
              data-name="Path 326"
              d="M1894.548,3122.894H1835.7a1.516,1.516,0,0,1-1.516-1.516v-33.021a1.516,1.516,0,0,1,1.516-1.516h32.263a1.516,1.516,0,1,1,0,3.032h-30.747v29.988h55.813v-29.988h-19.507a1.516,1.516,0,1,1,0-3.032h21.023a1.516,1.516,0,0,1,1.516,1.516v33.021a1.516,1.516,0,0,1-1.516,1.516"
              transform="translate(-1626.789 -2737.801)"
              fill="#fff"
            />
            <path
              id="Path_327"
              data-name="Path 327"
              d="M27.079,268.541a9.985,9.985,0,0,1,1.711-8.558,9.018,9.018,0,0,1,6.96-3.689l.412-.011,43.985.043a1.516,1.516,0,0,1,0,3.032h0L36.2,259.316l-.377.01a6.008,6.008,0,0,0-4.616,2.489,6.966,6.966,0,0,0-1.189,5.973,1.516,1.516,0,0,1-2.937.753"
              transform="translate(-23.732 -227.305)"
              fill="#fff"
            />
            <path
              id="Path_328"
              data-name="Path 328"
              d="M9.933,280.515a5.082,5.082,0,0,1-5.008-4.493c-.825-6.818-1.7-11.352-2.56-16.706a1.517,1.517,0,0,1,2.995-.481c.85,5.292,1.737,9.887,2.575,16.819a2.076,2.076,0,0,0,2,1.829l61.446-.058a2.1,2.1,0,0,0,2.022-1.856c2-16.854,4.5-33.349,6.253-51.46a.7.7,0,0,0-.151-.541.365.365,0,0,0-.287-.131l-17.65-.01a2.687,2.687,0,0,0-2.125,1.112l-7.852,13.02a1.516,1.516,0,0,1-1.3.733h0L3.95,238.266a1,1,0,0,0-.914,1.157c.168,2.343.51,5.036.684,6.824.179,1.829.843,4.983,1.051,6.662a1.516,1.516,0,1,1-3.009.373c-.213-1.7-.861-4.706-1.061-6.74-.192-1.972-.516-4.466-.69-6.9a4.017,4.017,0,0,1,3.937-4.41l45.49.025c6.535-10.834,7.447-12.358,7.524-12.467a5.7,5.7,0,0,1,4.607-2.4h0l17.649.01a3.381,3.381,0,0,1,2.534,1.129,3.724,3.724,0,0,1,.92,2.872c-1.784,18.372-4.256,34.658-6.26,51.52a5.108,5.108,0,0,1-5.027,4.532l-61.439.058Z"
              transform="translate(0 -195.473)"
              fill="#fff"
            />
            <path
              id="Path_329"
              data-name="Path 329"
              d="M201.767,510.492l-34.2-.019a3.754,3.754,0,0,1-3.794-3.722v-.628a3.768,3.768,0,0,1,3.809-3.718l34.2.019a3.754,3.754,0,0,1,3.794,3.722v.628a3.768,3.768,0,0,1-3.809,3.717"
              transform="translate(-145.255 -445.597)"
              fill="#fff"
            />
            <path
              id="Path_330"
              data-name="Path 330"
              d="M189.862,500.116h0l-34.2-.019a5.314,5.314,0,0,1-3.787-1.565,5.5,5.5,0,0,1-1.52-4.306A5.29,5.29,0,0,1,155.675,489h0l34.2.019a5.27,5.27,0,0,1,5.309,5.241l0,.63a5.29,5.29,0,0,1-5.323,5.229m0-3.032a2.254,2.254,0,0,0,2.291-2.2l0-.63a2.239,2.239,0,0,0-2.279-2.2l-34.2-.019a2.253,2.253,0,0,0-2.291,2.2l0,.63a2.238,2.238,0,0,0,2.279,2.2l34.2.019"
              transform="translate(-133.348 -433.705)"
              fill="#fff"
            />
            <path
              id="Path_331"
              data-name="Path 331"
              d="M538.265,281.114h-.55a1.516,1.516,0,0,1,0-3.032h.546a1.516,1.516,0,1,1,0,3.032"
              transform="translate(-475.572 -246.638)"
              fill="#fff"
            />
            <path
              id="Path_332"
              data-name="Path 332"
              d="M589.674,280.952a1.516,1.516,0,0,1,.024-2.144l.272-.267a1.516,1.516,0,1,1,2.12,2.168l-.272.266a1.516,1.516,0,0,1-2.144-.024"
              transform="translate(-522.613 -246.663)"
              fill="#fff"
            />
            <path
              id="Path_333"
              data-name="Path 333"
              d="M645.133,278.523l-.271-.532a1.516,1.516,0,0,1,2.7-1.38l.271.535a1.516,1.516,0,1,1-2.7,1.377"
              transform="translate(-571.797 -244.601)"
              fill="#fff"
            />
            <path
              id="Path_334"
              data-name="Path 334"
              d="M1414.645,2931.628h-24.278a1.517,1.517,0,0,1-1.2-.594l-29.4-38.408a1.516,1.516,0,0,1,2.409-1.843l28.947,37.813h23.529a1.516,1.516,0,1,1,0,3.032"
              transform="translate(-1205.73 -2563.384)"
              fill="#fff"
            />
            <path
              id="Path_335"
              data-name="Path 335"
              d="M2570.126,1705.4a3.647,3.647,0,1,0,.1-.289"
              transform="translate(-2279.396 -1510.406)"
              fill="#fff"
            />
            <path
              id="Path_336"
              data-name="Path 336"
              d="M2557.228,1693.034a1.625,1.625,0,0,1,.078-.2,1.55,1.55,0,0,1,.066-.206,5.163,5.163,0,1,1,5.816,6.979c-1.632.331-7.035-3.053-5.96-6.57m2.924.8c-.007.029-.015.058-.024.086a2.131,2.131,0,1,0,.059-.168c-.011.028-.022.055-.035.082"
              transform="translate(-2267.948 -1498.487)"
              fill="#fff"
            />
            <path
              id="Path_337"
              data-name="Path 337"
              d="M1770.95,1697.467a13.028,13.028,0,0,0-13.013,13.013c0,6.647,10.577,21.529,11.782,23.2a1.517,1.517,0,0,0,2.462,0c1.205-1.675,11.782-16.557,11.782-23.2a13.028,13.028,0,0,0-13.013-13.013m0,16.15a4.927,4.927,0,1,1,4.927-4.927,4.927,4.927,0,0,1-4.927,4.927"
              transform="translate(-1559.161 -1505.528)"
              fill="#fff"
            />
            <path
              id="Path_338"
              data-name="Path 338"
              d="M2046.12,1794.416c-12.667-4.072-16.01-9.612-22.481-10.588a17.157,17.157,0,0,0-11.1,2.055,20.9,20.9,0,0,0-7.355,8.151,1.516,1.516,0,1,1-2.756-1.265,23.734,23.734,0,0,1,8.463-9.431,20.106,20.106,0,0,1,13.2-2.51c7.234,1.092,10.851,6.808,22.957,10.7,4.8,1.542,8.442,1.772,11.483.726,3.243-1.116,6.424-4.609,6.129-8.519a1.516,1.516,0,0,1,3.024-.228c.42,5.573-3.894,10.143-8.166,11.614-4.358,1.5-9.032.7-13.4-.707"
              transform="translate(-1775.884 -1579.271)"
              fill="#fff"
            />
            <path
              id="Path_339"
              data-name="Path 339"
              d="M1854.614,2053a2.53,2.53,0,1,1-2.527-2.653,2.592,2.592,0,0,1,2.527,2.653"
              transform="translate(-1640.424 -1818.51)"
              fill="#fff"
            />
            <path
              id="Path_340"
              data-name="Path 340"
              d="M2122.072,1998.632h-34.121a1.516,1.516,0,1,1,0-3.032h34.121a1.516,1.516,0,1,1,0,3.032"
              transform="translate(-1850.514 -1769.951)"
              fill="#fff"
            />
            <path
              id="Path_341"
              data-name="Path 341"
              d="M2231.181,2076.846H2219.8a1.516,1.516,0,0,1,0-3.032h11.383a1.516,1.516,0,0,1,0,3.032"
              transform="translate(-1967.453 -1839.32)"
              fill="#fff"
            />
            <path
              id="Path_342"
              data-name="Path 342"
              d="M1150.553,1486.745c-12.287,0-22.247,10.579-22.247,23.629s9.96,23.629,22.247,23.629,22.247-10.579,22.247-23.629-9.96-23.629-22.247-23.629m-6.34,11.535h0c.721-2.305,3.437-5.453,6.539-5.083a6.353,6.353,0,0,1,.676,12.466,6.633,6.633,0,0,1-1.911.285,5.69,5.69,0,0,1-2.529-.568,6,6,0,0,1-2.776-7.1m-8.319,23.452a19.911,19.911,0,0,1,6.134-9.15,13.806,13.806,0,0,1,11.155-2.941,15.256,15.256,0,0,1,7.944,5.112,15.826,15.826,0,0,1,3.055,7.056,264.352,264.352,0,0,0-28.288-.077"
              transform="translate(-1000.724 -1318.633)"
              fill="#fff"
            />
            <path
              id="Path_343"
              data-name="Path 343"
              d="M765.267,1063.738H673.258a1.516,1.516,0,0,0-1.516,1.516v34.365a1.516,1.516,0,0,0,1.516,1.516h92.009a1.516,1.516,0,0,0,1.516-1.516v-34.365a1.516,1.516,0,0,0-1.516-1.516m-7.39,8.972-12.317,14.719a1.009,1.009,0,0,1-1.512.043l-11.215-11.947a32.54,32.54,0,0,0-5.493,7.5,1.01,1.01,0,0,1-1.715.112l-7.145-10.02c-4.279,5.663-8.088,11.649-12.357,17.448,1.553,2.264.073,5.483-2.525,5.483a3.42,3.42,0,0,1-2.823-4.95c-3.6-3.874-7.224-7.255-10.72-11.139l-9.8,12.752c1.707,2.253.229,5.611-2.428,5.611a3.449,3.449,0,0,1,0-6.874,2.893,2.893,0,0,1,.763.112l10.61-13.8a1.013,1.013,0,0,1,.769-.394.994.994,0,0,1,.794.345c3.55,4.058,6.968,7.181,11.355,11.886a2.766,2.766,0,0,1,2.38-.262c4.943-6.718,8.66-12.643,13.214-18.534a1.01,1.01,0,0,1,1.623.031l7.017,9.84a34.6,34.6,0,0,1,5.813-7.318,1.009,1.009,0,0,1,1.437.038l11.145,11.872,11.817-14.121a3.421,3.421,0,0,1,2.881-4.834,3.307,3.307,0,0,1,3.159,3.437c0,2.588-2.568,4.315-4.721,2.97"
              transform="translate(-595.786 -943.458)"
              fill="#fff"
            />
            <path
              id="Path_344"
              data-name="Path 344"
              d="M1136.948,1954.63h-56.1a1.516,1.516,0,0,0-1.516,1.516v14.487a1.516,1.516,0,0,0,1.516,1.516h56.1a1.516,1.516,0,0,0,1.516-1.516v-14.487a1.516,1.516,0,0,0-1.516-1.516m-47.5,12.3a3.746,3.746,0,1,1,3.2-3.706,3.481,3.481,0,0,1-3.2,3.706m11.792,0a3.746,3.746,0,1,1,3.2-3.706,3.481,3.481,0,0,1-3.2,3.706m12.634,0a3.746,3.746,0,1,1,3.2-3.706,3.481,3.481,0,0,1-3.2,3.706m13.645,0a3.746,3.746,0,1,1,3.2-3.706,3.481,3.481,0,0,1-3.2,3.706"
              transform="translate(-957.292 -1733.613)"
              fill="#fff"
            />
            <line
              id="Line_39"
              data-name="Line 39"
              x2="36.089"
              transform="translate(229.351 110.005)"
              fill="#fff"
            />
            <path
              id="Path_345"
              data-name="Path 345"
              d="M2052.53,962.486h-36.089a1.516,1.516,0,1,1,0-3.032h36.089a1.516,1.516,0,1,1,0,3.032"
              transform="translate(-1787.09 -850.965)"
              fill="#fff"
            />
            <line
              id="Line_40"
              data-name="Line 40"
              x2="26.288"
              transform="translate(233.394 121.123)"
              fill="#fff"
            />
            <path
              id="Path_346"
              data-name="Path 346"
              d="M2078.484,1060.812H2052.2a1.516,1.516,0,0,1,0-3.032h26.288a1.516,1.516,0,0,1,0,3.032"
              transform="translate(-1818.802 -938.173)"
              fill="#fff"
            />
            <path
              id="Path_347"
              data-name="Path 347"
              d="M1178.286,2199.007h-40.817a1.516,1.516,0,0,1,0-3.032h40.817a1.516,1.516,0,0,1,0,3.032"
              transform="translate(-1007.507 -1947.668)"
              fill="#fff"
            />
            <path
              id="Path_348"
              data-name="Path 348"
              d="M1235.408,2362.886h-86.024a1.516,1.516,0,0,1,0-3.032h86.024a1.516,1.516,0,0,1,0,3.032"
              transform="translate(-1018.074 -2093.017)"
              fill="#fff"
            />
            <path
              id="Path_349"
              data-name="Path 349"
              d="M1526.577,2499.947h-25.606a1.516,1.516,0,1,1,0-3.032h25.606a1.516,1.516,0,1,1,0,3.032"
              transform="translate(-1329.906 -2214.58)"
              fill="#fff"
            />
            <path
              id="Path_350"
              data-name="Path 350"
              d="M1968.33,3271.164h-4.8V3257.1a.2.2,0,0,1,.2-.2h4.405a.2.2,0,0,1,.2.2Z"
              transform="translate(-1741.505 -2888.63)"
              fill="#fff"
            />
            <path
              id="Path_351"
              data-name="Path 351"
              d="M2094.483,3231.169h-5.812v-19.487a.2.2,0,0,1,.2-.2h5.416a.2.2,0,0,1,.2.2Z"
              transform="translate(-1852.497 -2848.35)"
              fill="#fff"
            />
            <path
              id="Path_352"
              data-name="Path 352"
              d="M2236.744,3177.463h-5.054v-26.334a.2.2,0,0,1,.2-.2h4.658a.2.2,0,0,1,.2.2Z"
              transform="translate(-1979.344 -2794.644)"
              fill="#fff"
            />
            <path
              id="Path_353"
              data-name="Path 353"
              d="M1956.847,380.336a1.516,1.516,0,0,1-1.516-1.516V359.094a1.516,1.516,0,1,1,3.032,0V378.82a1.516,1.516,0,0,1-1.516,1.516"
              transform="translate(-1734.234 -317.146)"
              fill="#fff"
            />
            <path
              id="Path_354"
              data-name="Path 354"
              d="M556.9,2794.685l-3.526-7.133a1.516,1.516,0,1,1,2.718-1.343l3.526,7.132a1.516,1.516,0,1,1-2.718,1.344"
              transform="translate(-490.667 -2470.412)"
              fill="#fff"
            />
            <path
              id="Path_355"
              data-name="Path 355"
              d="M584.208,2837.636a1.518,1.518,0,0,1,1.072-1.857l6.961-1.868a1.516,1.516,0,0,1,.786,2.928l-6.961,1.869a1.517,1.517,0,0,1-1.858-1.071"
              transform="translate(-518.104 -2513.423)"
              fill="#fff"
            />
            <path
              id="Path_356"
              data-name="Path 356"
              d="M651.267,2788.571a1.516,1.516,0,0,1-.887-1.952l2.146-5.73a1.516,1.516,0,1,1,2.839,1.064l-2.146,5.73a1.516,1.516,0,0,1-1.951.888"
              transform="translate(-576.753 -2465.57)"
              fill="#fff"
            />
            <path
              id="Path_357"
              data-name="Path 357"
              d="M678.691,2784.53l-7.561-1.774a1.516,1.516,0,0,1,.693-2.952l7.561,1.774a1.516,1.516,0,0,1-.693,2.952"
              transform="translate(-594.205 -2465.445)"
              fill="#fff"
            />
            <path
              id="Path_358"
              data-name="Path 358"
              d="M726.211,2804.737a1.517,1.517,0,0,1-1.154-1.807l1.455-6.585a1.516,1.516,0,0,1,2.961.653l-1.455,6.585a1.517,1.517,0,0,1-1.807,1.154"
              transform="translate(-643.041 -2479.097)"
              fill="#fff"
            />
            <path
              id="Path_359"
              data-name="Path 359"
              d="M730.536,2861.37a1.515,1.515,0,0,1-1.243-.646,17.671,17.671,0,0,0-4.906-4.7,1.516,1.516,0,0,1,1.634-2.555,20.744,20.744,0,0,1,5.755,5.515,1.518,1.518,0,0,1-1.241,2.386"
              transform="translate(-641.858 -2530.605)"
              fill="#fff"
            />
            <path
              id="Path_360"
              data-name="Path 360"
              d="M774.741,2877.574a1.516,1.516,0,0,1-.792-2.81,33.727,33.727,0,0,1,6.861-3.192,1.516,1.516,0,1,1,.961,2.875,30.709,30.709,0,0,0-6.241,2.9,1.509,1.509,0,0,1-.789.222"
              transform="translate(-685.793 -2546.803)"
              fill="#fff"
            />
            <path
              id="Path_361"
              data-name="Path 361"
              d="M837.289,2882.128a32.31,32.31,0,0,0-4.331-6.856,1.516,1.516,0,1,1,2.339-1.928,35.331,35.331,0,0,1,4.738,7.5,1.516,1.516,0,1,1-2.746,1.285"
              transform="translate(-738.464 -2547.954)"
              fill="#fff"
            />
            <path
              id="Path_362"
              data-name="Path 362"
              d="M823.158,2945.781a1.517,1.517,0,0,1,.535-2.076,19.081,19.081,0,0,0,4.792-4.016,1.516,1.516,0,0,1,2.3,1.975,22.115,22.115,0,0,1-5.552,4.652,1.516,1.516,0,0,1-2.077-.534"
              transform="translate(-729.894 -2606.818)"
              fill="#fff"
            />
            <path
              id="Path_363"
              data-name="Path 363"
              d="M822.879,2990.1a1.516,1.516,0,0,1-1.516-1.492l-.082-5.138a1.517,1.517,0,0,1,1.491-1.541h.025a1.517,1.517,0,0,1,1.516,1.491l.082,5.141a1.516,1.516,0,0,1-1.492,1.54Z"
              transform="translate(-728.415 -2644.755)"
              fill="#fff"
            />
            <path
              id="Path_364"
              data-name="Path 364"
              d="M831.3,3033.922a33.12,33.12,0,0,0-5.9-2.189,1.516,1.516,0,0,1,.94-2.883c.376.123.774.247,1.181.373a26.286,26.286,0,0,1,5.311,2.08,1.516,1.516,0,0,1-1.53,2.619"
              transform="translate(-731.142 -2686.3)"
              fill="#fff"
            />
            <path
              id="Path_365"
              data-name="Path 365"
              d="M861.63,3064.24a1.515,1.515,0,0,1-1.1-1.838c.241-.962.455-1.927.672-2.906.378-1.7.768-3.453,1.319-5.32a1.516,1.516,0,1,1,2.908.857c-.835,2.835-1.28,5.393-1.957,8.1a1.6,1.6,0,0,1-1.839,1.1"
              transform="translate(-763.184 -2707.864)"
              fill="#fff"
            />
            <path
              id="Path_366"
              data-name="Path 366"
              d="M806.424,3118a42.314,42.314,0,0,1-6.895-1.715,1.516,1.516,0,1,1,.944-2.881,39.5,39.5,0,0,0,6.429,1.6,1.516,1.516,0,1,1-.478,2.994"
              transform="translate(-708.197 -2761.292)"
              fill="#fff"
            />
            <path
              id="Path_367"
              data-name="Path 367"
              d="M756.659,3118.472a1.516,1.516,0,0,1,.508-2.083,12.694,12.694,0,0,0,4.357-3.858,1.516,1.516,0,0,1,2.582,1.589,15.529,15.529,0,0,1-5.364,4.86,1.517,1.517,0,0,1-2.084-.508"
              transform="translate(-670.905 -2759.947)"
              fill="#fff"
            />
            <path
              id="Path_368"
              data-name="Path 368"
              d="M760.111,3159.881a44.838,44.838,0,0,0-2.4-7.311,1.516,1.516,0,0,1,2.789-1.19,47.828,47.828,0,0,1,2.562,7.808,1.516,1.516,0,1,1-2.951.694"
              transform="translate(-671.925 -2794.224)"
              fill="#fff"
            />
            <path
              id="Path_369"
              data-name="Path 369"
              d="M720.128,3223.275a1.516,1.516,0,0,1-.463-2.96,25.884,25.884,0,0,0,6.564-3.182,1.516,1.516,0,1,1,1.694,2.515,28.907,28.907,0,0,1-7.332,3.554,1.531,1.531,0,0,1-.463.073"
              transform="translate(-637.355 -2853.132)"
              fill="#fff"
            />
            <path
              id="Path_370"
              data-name="Path 370"
              d="M692.786,3207.755a1.514,1.514,0,0,1-1.27-.687l-3.51-5.376a1.516,1.516,0,0,1,2.539-1.658l3.51,5.376a1.516,1.516,0,0,1-1.268,2.345"
              transform="translate(-609.991 -2837.584)"
              fill="#fff"
            />
            <path
              id="Path_371"
              data-name="Path 371"
              d="M628.251,3203.589a1.516,1.516,0,0,1-.041-3.032c2.264-.063,3.757-.331,6.536-.635a1.516,1.516,0,1,1,.33,3.014c-.768.084-1.443.166-2.07.242a46.519,46.519,0,0,1-4.711.41h-.043"
              transform="translate(-555.869 -2838.088)"
              fill="#fff"
            />
            <path
              id="Path_372"
              data-name="Path 372"
              d="M601.476,3214.254a1.517,1.517,0,0,1-1.369-2.166l3.052-6.448a1.516,1.516,0,1,1,2.74,1.3l-3.052,6.448a1.516,1.516,0,0,1-1.371.868"
              transform="translate(-532.121 -2842.396)"
              fill="#fff"
            />
            <path
              id="Path_373"
              data-name="Path 373"
              d="M542.8,3247.4a1.5,1.5,0,0,1-.286-.028,31.366,31.366,0,0,1-5.449-1.6c-.661-.24-1.4-.51-2.291-.817a1.516,1.516,0,0,1,.991-2.866c.905.313,1.661.588,2.335.833a28.215,28.215,0,0,0,4.982,1.47,1.516,1.516,0,0,1-.282,3.006"
              transform="translate(-473.4 -2875.422)"
              fill="#fff"
            />
            <path
              id="Path_374"
              data-name="Path 374"
              d="M534.785,3191.565a1.57,1.57,0,0,1-.265-.023,1.517,1.517,0,0,1-1.231-1.756l1.2-6.822a1.516,1.516,0,0,1,2.987.525l-1.2,6.821a1.517,1.517,0,0,1-1.491,1.254"
              transform="translate(-472.968 -2821.943)"
              fill="#fff"
            />
            <path
              id="Path_375"
              data-name="Path 375"
              d="M509.072,3149.065c-1.521-1.53-3.091-3.145-4.638-4.7a1.516,1.516,0,0,1,2.15-2.139c1.607,1.615,3.068,3.122,4.638,4.7a1.516,1.516,0,0,1-2.15,2.139"
              transform="translate(-447.004 -2786.527)"
              fill="#fff"
            />
            <path
              id="Path_376"
              data-name="Path 376"
              d="M442.43,3147.61a1.516,1.516,0,0,1,.782-2c2.3-1.007,4.6-2.115,6.825-3.293a1.516,1.516,0,0,1,1.419,2.68c-2.291,1.213-4.656,2.354-7.031,3.392a1.516,1.516,0,0,1-2-.782"
              transform="translate(-392.29 -2786.851)"
              fill="#fff"
            />
            <path
              id="Path_377"
              data-name="Path 377"
              d="M403.136,3118.564a26.459,26.459,0,0,1-4.941-7.508,1.517,1.517,0,0,1,2.78-1.214,23.434,23.434,0,0,0,4.375,6.65,1.516,1.516,0,1,1-2.213,2.072"
              transform="translate(-353.057 -2757.395)"
              fill="#fff"
            />
            <path
              id="Path_378"
              data-name="Path 378"
              d="M398.549,3077.649a1.517,1.517,0,0,1,.43-2.1l6.7-4.424a1.516,1.516,0,1,1,1.67,2.531l-6.7,4.424a1.517,1.517,0,0,1-2.1-.43"
              transform="translate(-353.261 -2723.639)"
              fill="#fff"
            />
            <path
              id="Path_379"
              data-name="Path 379"
              d="M445.376,3021.526c-.189-1.05-.438-2.164-.68-3.242-.249-1.109-.506-2.257-.706-3.371a1.516,1.516,0,0,1,2.985-.535c.189,1.05.438,2.164.68,3.242.249,1.109.506,2.256.706,3.371a1.516,1.516,0,1,1-2.985.535"
              transform="translate(-393.765 -2672.423)"
              fill="#fff"
            />
            <path
              id="Path_380"
              data-name="Path 380"
              d="M395.826,2997.136a1.517,1.517,0,0,1-.541-.1l-6.422-2.454a1.516,1.516,0,1,1,1.082-2.833l6.422,2.453a1.517,1.517,0,0,1-.541,2.933"
              transform="translate(-344.028 -2653.374)"
              fill="#fff"
            />
            <path
              id="Path_381"
              data-name="Path 381"
              d="M387.991,2932.621a1.518,1.518,0,0,1-1.422-2.042l2.841-7.683a1.516,1.516,0,0,1,2.845,1.051l-2.841,7.683a1.517,1.517,0,0,1-1.422.991"
              transform="translate(-342.774 -2591.514)"
              fill="#fff"
            />
            <path
              id="Path_382"
              data-name="Path 382"
              d="M418.943,2926.931a22.027,22.027,0,0,1-6.675-1.036,1.516,1.516,0,1,1,.924-2.888,18.949,18.949,0,0,0,7.216.834,1.516,1.516,0,1,1,.241,3.022q-.848.068-1.706.068"
              transform="translate(-364.716 -2592.428)"
              fill="#fff"
            />
            <path
              id="Path_383"
              data-name="Path 383"
              d="M481.692,2894.175a1.516,1.516,0,0,1-1.232-2.4l3.558-4.968a1.516,1.516,0,0,1,2.466,1.766l-3.558,4.967a1.515,1.515,0,0,1-1.234.634"
              transform="translate(-425.881 -2559.826)"
              fill="#fff"
            />
            <path
              id="Path_384"
              data-name="Path 384"
              d="M486.153,2832.605l-3.292-6.912a1.516,1.516,0,0,1,2.738-1.3l3.292,6.912a1.516,1.516,0,0,1-2.738,1.3"
              transform="translate(-428.132 -2504.259)"
              fill="#fff"
            />
            <path
              id="Path_385"
              data-name="Path 385"
              d="M483.035,2792.934a1.517,1.517,0,0,1,.777-2c1.289-.568,2.531-1.251,3.846-1.976s2.673-1.474,4.086-2.1a1.516,1.516,0,0,1,1.222,2.776c-2.589,1.14-5.183,2.861-7.932,4.072-.2.087-1.661-.009-2-.777"
              transform="translate(-428.302 -2471.629)"
              fill="#fff"
            />
            <path
              id="Path_386"
              data-name="Path 386"
              d="M552.565,2911.809a1.558,1.558,0,0,0-.128-.17l-1.321-1.523c-.025-.029-.051-.057-.079-.085-3.715-3.67-6.868-4.957-11.582-4.745a14.608,14.608,0,0,0-13.3,11.344c-1.643,7.907,4.206,17.149,13.859,17.149a14.687,14.687,0,0,0,10.929-4.752,14.5,14.5,0,0,0,1.618-17.219m-2.2,3.988a118.5,118.5,0,0,0-12.07,10.02,1.01,1.01,0,0,1-1.463-.075l-6.026-7.021a1.011,1.011,0,0,1,1.534-1.317l5.337,6.219a120.473,120.473,0,0,1,11.506-9.467,1.011,1.011,0,0,1,1.182,1.64"
              transform="translate(-466.421 -2576.755)"
              fill="#fff"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}
