import React from "react";
import "./Footer.scss";

export default function Footer() {
  return (
    <div className="px-10 lg:px-20 bg-black flex flex-col lg:flex-row items-center justify-center lg:justify-between gap-2 lg:gap-0 py-5">
      <div>
        <p className="font-thin text-white text-lg text-center lg:text-left">
          Atrius Technologies
        </p>
        <p className="font-thin text-white text-lg text-center lg:text-left">
          Kochi, India | Dubai, UAE
        </p>
      </div>
      <div>
        <p className="font-thin text-white text-lg text-center lg:text-right">
          Mail:{" "}
          <a href="mailto:hello@atriustechnologies.com">
            hello@atriustechnologies.com
          </a>
        </p>
        <p className="font-thin text-white text-lg text-center lg:text-right">
          Call: <a href="tel:+919746144499"> +91 97461 444 99</a>
        </p>
      </div>
    </div>
  );
}
