import React from "react";
import IMG from "../../../assets/bim/building.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
export default function Buildings() {
  const array = [
    {
      id: 1,
      title: "STRUCTURAL",
      card: [
        {
          id: 1,
          title: "Detailed 3D modeling of structural components.",
        },
        {
          id: 2,
          title:
            "Integration with analysis tools like ETABS and Robot Structural Analysis. ",
        },
        {
          id: 3,
          title:
            "Precise rebar design and placement with reinforcement detailing.",
        },
        {
          id: 4,
          title:
            "Extraction of 2D drawings including layouts, sections, and detailed views. ",
        },
        {
          id: 5,
          title: "Automated quantity takeoffs for accurate costing.",
        },
      ],
    },
    {
      id: 2,
      title: "ARCHITECTURAL & INTERIOR DESIGN",
      card: [
        {
          id: 1,
          title: "Transformation of schematics into detailed interior models.",
        },
        {
          id: 2,
          title:
            "Creation of 3D visuals with Virtual Reality (VR) integration.",
        },
        {
          id: 3,
          title:
            "Production of floor finish shop drawings, RCPs, and furniture plans.",
        },
        {
          id: 4,
          title: "BIM coordination for resolving MEP and structural overlaps.",
        },
        {
          id: 5,
          title: "Design of façade details and custom parametric elements.",
        },
      ],
    },
    {
      id: 3,
      title: "MEP",
      card: [
        {
          id: 1,
          title: "In-depth design development for MEP systems.",
        },
        {
          id: 2,
          title:
            "Preparation of detailed design drawings, shop drawings, and fabrication schematics.",
        },
        {
          id: 3,
          title:
            "Streamlined MEP model coordination to minimize clashes and enhance construction efficiency.",
        },
        {
          id: 4,
          title:
            "Detailed layouts and perspectives of MEP-focused rooms for service room layouts.",
        },
        {
          id: 5,
          title:
            "Integration of BIM with specification info, COBie sheets, and asset register genesis.",
        },
      ],
    },
  ];
  return (
    <div className="w-full flex flex-col justify-center items-center relative bg-black">
      <img src={IMG} alt="img" className="w-full" />
      <div className="max-w-screen-2xl relative lg:absolute w-full h-auto flex justify-center lg:justify-end items-center ">
        <Carousel
          autoPlay={true}
          dynamicHeight={true}
          infiniteLoop={true}
          showArrows={false}
          showStatus={false}
          showIndicators={true}
          useKeyboardArrows={true}
          swipeable={true}
          emulateTouch={true}
          showThumbs={false}
          interval={3000}
          className="w-[90%] sm:w-[70%] lg:w-[45%] h-full"
        >
          {array.map((data) => (
            <div className="px-10 py-[50px] h-full" key={data.id}>
              <p className="text-white text-4xl font-bold text-left my-5">{data.title}</p>
              {data.card.map((items) => (
                <div className="flex items-start w-full  text-left  my-2" key={items.id}>
                  <div className="bg-primary h-[2px] w-[5%] my-3" />
                  <p className="text-white text-xl font-extralight pl-2 w-[92%]">
                    {items.title}
                  </p>
                </div>
              ))}
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}
