import React from "react";
import IMG from "../../../assets/bim/img2.png";
export default function Image2() {
  return (
    <div className="w-full bg-black flex justify-center py-20">
      <img src={IMG} alt="bim" className="w-[350px] md:w-[700px]" />
     
    </div>
  );
}
