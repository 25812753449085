import React from "react";
import HERO from "../../../assets/ai/hero.png";
export default function Hero() {
  return (
    <div className="h-auto lg:h-[800px] w-fill relative flex justify-center items-end ">
      <div className="bg-gray-500 w-full h-full absolute">
        <img src={HERO} alt="hero" className="h-full w-full object-cover" />
      </div>
      <div className="bg-gradient-to-t from-black w-full px-10 lg:px-0 mt-[300px] lg:mt-0 flex justify-center relative">
        <div className=" max-w-screen-2xl flex flex-col lg:flex-row justify-center items-center relative">
          <div className="w-[90%] mr-0 lg:mr-10">
            <p className="text-white text-3xl sm:text-4xl xl:text-5xl font-extralight text-center lg:text-end w-auto ">
              Turbocharge Your Digital Adventure with
            </p>
          </div>
          <div className="w-full ">
            <p className="text-white text-7xl sm:text-8xl xl:text-9xl font-thin text-center lg:text-start">
              TECH & AI
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
