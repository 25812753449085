import React from "react";
import { motion } from "framer-motion";
export default function WebDesign() {
  return (
    <svg
      id="Group_76"
      data-name="Group 76"
      xmlns="http://www.w3.org/2000/svg"
      width="408.495"
      height="331.735"
      viewBox="0 0 408.495 331.735"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_51"
            data-name="Rectangle 51"
            width="408.495"
            height="331.735"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <rect
            id="Rectangle_52"
            data-name="Rectangle 52"
            width="408.495"
            height="331.735"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g id="Group_73" data-name="Group 73">
        <g id="Group_72" data-name="Group 72" clip-path="url(#clip-path)">
          <path
            id="Path_148"
            data-name="Path 148"
            d="M9.676,132.577v.55a27.537,27.537,0,0,0,27.537,27.537h287.6a27.538,27.538,0,0,0,27.539-27.537v-.55Z"
            transform="translate(8.853 121.3)"
            fill="#fff"
          />
          <path
            id="Path_149"
            data-name="Path 149"
            d="M328.273,215.582H18.183V51.534A15.956,15.956,0,0,1,34.14,35.577H312.316a15.958,15.958,0,0,1,15.957,15.957Z"
            transform="translate(16.636 32.551)"
            fill="#dbdbdb"
          />
          <rect
            id="Rectangle_41"
            data-name="Rectangle 41"
            width="283.356"
            height="149.365"
            transform="translate(48.188 83.447)"
            fill="#f9fbfc"
          />
          <path
            id="Path_150"
            data-name="Path 150"
            d="M103.394,40.949a2.872,2.872,0,1,1-2.872-2.872,2.872,2.872,0,0,1,2.872,2.872"
            transform="translate(89.343 34.838)"
            fill="#1d262d"
          />
          <path
            id="Path_151"
            data-name="Path 151"
            d="M17,24.535a3.625,3.625,0,1,1-3.625-3.625A3.625,3.625,0,0,1,17,24.535"
            transform="translate(8.918 19.131)"
            fill="#fff"
          />
          <path
            id="Path_152"
            data-name="Path 152"
            d="M26.575,24.535A3.625,3.625,0,1,1,22.95,20.91a3.626,3.626,0,0,1,3.625,3.625"
            transform="translate(17.681 19.131)"
            fill="#fff"
          />
          <path
            id="Path_153"
            data-name="Path 153"
            d="M36.153,24.535a3.625,3.625,0,1,1-3.625-3.625,3.626,3.626,0,0,1,3.625,3.625"
            transform="translate(26.445 19.131)"
            fill="#fff"
          />
          <path
            id="Path_154"
            data-name="Path 154"
            d="M45.731,24.535a3.625,3.625,0,1,1-3.625-3.625,3.625,3.625,0,0,1,3.625,3.625"
            transform="translate(35.208 19.131)"
            fill="#fff"
          />
          <path
            id="Path_155"
            data-name="Path 155"
            d="M55.309,24.535a3.625,3.625,0,1,1-3.625-3.625,3.625,3.625,0,0,1,3.625,3.625"
            transform="translate(43.971 19.131)"
            fill="#fff"
          />
          <path
            id="Path_156"
            data-name="Path 156"
            d="M155.723,157.019a3.626,3.626,0,1,1-3.625-3.625,3.625,3.625,0,0,1,3.625,3.625"
            transform="translate(135.842 140.347)"
            fill="#fff"
          />
          <path
            id="Path_157"
            data-name="Path 157"
            d="M165.3,157.019a3.625,3.625,0,1,1-3.625-3.625,3.625,3.625,0,0,1,3.625,3.625"
            transform="translate(144.607 140.347)"
            fill="#fff"
          />
          <path
            id="Path_158"
            data-name="Path 158"
            d="M174.878,157.019a3.625,3.625,0,1,1-3.625-3.625,3.626,3.626,0,0,1,3.625,3.625"
            transform="translate(153.37 140.347)"
            fill="#fff"
          />
          <path
            id="Path_159"
            data-name="Path 159"
            d="M184.456,157.019a3.625,3.625,0,1,1-3.625-3.625,3.625,3.625,0,0,1,3.625,3.625"
            transform="translate(162.133 140.347)"
            fill="#fff"
          />
          <path
            id="Path_160"
            data-name="Path 160"
            d="M194.034,157.019a3.625,3.625,0,1,1-3.625-3.625,3.625,3.625,0,0,1,3.625,3.625"
            transform="translate(170.896 140.347)"
            fill="#fff"
          />
          <path
            id="Path_161"
            data-name="Path 161"
            d="M260.752,108.783a5.744,5.744,0,0,1-11.488,0v-1.492a8.937,8.937,0,1,0-17.874,0v1.492a5.744,5.744,0,0,1-11.488,0v-1.492a8.937,8.937,0,0,0-17.874,0v1.492a5.744,5.744,0,0,1-11.488,0v-1.492a8.937,8.937,0,1,0-17.874,0v1.492a5.744,5.744,0,0,1-11.488,0v-.393h-3.192v.393a8.937,8.937,0,0,0,17.874,0v-1.492a5.743,5.743,0,1,1,11.486,0v1.492a8.938,8.938,0,0,0,17.876,0v-1.492a5.743,5.743,0,1,1,11.486,0v1.492a8.938,8.938,0,0,0,17.876,0v-1.492a5.743,5.743,0,1,1,11.486,0v1.492a8.938,8.938,0,0,0,17.876,0Z"
            transform="translate(144.549 89.988)"
            fill="#fff"
          />
          <path
            id="Path_162"
            data-name="Path 162"
            d="M63.758,150.334H199.3V61.243H63.758Zm3.83-72.653H195.47V146.5H67.588Z"
            transform="translate(58.335 56.034)"
            fill="#1d262d"
          />
          <rect
            id="Rectangle_42"
            data-name="Rectangle 42"
            width="10.917"
            height="9.521"
            transform="translate(125.923 121.065)"
            fill="#f9fbfc"
          />
          <rect
            id="Rectangle_43"
            data-name="Rectangle 43"
            width="114.014"
            height="9.521"
            transform="translate(139.791 121.065)"
            fill="#f9fbfc"
          />
          <rect
            id="Rectangle_44"
            data-name="Rectangle 44"
            width="62.703"
            height="2.633"
            transform="translate(133.14 145.867)"
            fill="#dbdbdb"
          />
          <rect
            id="Rectangle_45"
            data-name="Rectangle 45"
            width="62.703"
            height="2.633"
            transform="translate(133.14 155.807)"
            fill="#dbdbdb"
          />
          <rect
            id="Rectangle_46"
            data-name="Rectangle 46"
            width="62.703"
            height="2.633"
            transform="translate(133.14 165.75)"
            fill="#dbdbdb"
          />
          <rect
            id="Rectangle_47"
            data-name="Rectangle 47"
            width="62.703"
            height="2.633"
            transform="translate(133.14 175.69)"
            fill="#dbdbdb"
          />
          <rect
            id="Rectangle_48"
            data-name="Rectangle 48"
            width="62.703"
            height="2.633"
            transform="translate(133.14 185.631)"
            fill="#dbdbdb"
          />
          <rect
            id="Rectangle_49"
            data-name="Rectangle 49"
            width="41.65"
            height="51.464"
            transform="translate(204.939 141.334)"
            fill="#dbdbdb"
          />
          <path
            id="Path_163"
            data-name="Path 163"
            d="M146.023,131.672H235.3V42.4Zm86.463-78.836v8.435H228.27v2.811h4.217v5.624h-2.811v2.811h2.811v5.624H228.27v2.811h4.217v5.624h-2.811v2.811h2.811v5.624H228.27v2.813h4.217v5.622h-2.811v2.813h2.811v5.622H228.27V114.7h4.217v5.622h-2.811v2.813h2.811v5.622h-5.641v-2.706h-2.811v2.811H218.41v-4.217H215.6v4.217h-5.624v-2.811h-2.811v2.811h-5.624v-4.217h-2.811v4.217H193.1v-2.811h-2.811v2.811h-5.624v-4.217h-2.811v4.217h-5.624v-2.811h-2.811v2.811H167.8v-4.217h-2.811v4.217H152.825L232.486,49.2Z"
            transform="translate(133.603 38.791)"
            fill="#cfcfcf"
          />
          <path
            id="Path_164"
            data-name="Path 164"
            d="M206.652,103.449V59.216l-44.233,44.233Zm-2.811-2.811h-34.62l34.62-34.62Z"
            transform="translate(148.604 54.179)"
            fill="#cfcfcf"
          />
          <path
            id="Path_165"
            data-name="Path 165"
            d="M18.766,73.28l-3.3-7.034L10.552,55.774,5.627,66.247,2.322,73.278v85.77H18.766Zm-6.653-7.034L14.859,72.1H6.228L8.98,66.247ZM5.355,75.132H9.026v69.122H5.355ZM15.73,156.014H5.355v-8.723l10.375,0Zm0-11.76H12.059V75.132H15.73Z"
            transform="translate(2.124 51.03)"
            fill="#fff"
          />
          <path
            id="Path_166"
            data-name="Path 166"
            d="M77.3,21.951,87.467,10.913,84.707,8.37,72.2,21.951,84.707,35.528l2.759-2.539Z"
            transform="translate(66.058 7.658)"
            fill="#fff"
          />
          <path
            id="Path_167"
            data-name="Path 167"
            d="M105.06,8.37,102.3,10.914l10.168,11.038L102.3,32.987l2.759,2.541,12.508-13.577Z"
            transform="translate(93.599 7.659)"
            fill="#fff"
          />
          <rect
            id="Rectangle_50"
            data-name="Rectangle 50"
            width="40.197"
            height="3.75"
            transform="translate(162.344 45.559) rotate(-57.541)"
            fill="#fff"
          />
          {/* <path
            id="Path_168"
            data-name="Path 168"
            d="M142.361,23.582a4.359,4.359,0,0,1,1.092-4.843,24.8,24.8,0,0,0-2.277-2.141,4.176,4.176,0,0,1-4.739,1.084,4.346,4.346,0,0,1-2.648-4.253,26.428,26.428,0,0,0-3.123.107,4.1,4.1,0,0,1-2.589,4.065,4.466,4.466,0,0,1-4.851-1.1,25.074,25.074,0,0,0-2.168,2.3,4.078,4.078,0,0,1,1.08,4.741,4.48,4.48,0,0,1-4.2,2.65A18.721,18.721,0,0,0,118,29.347a4.115,4.115,0,0,1,4.077,2.587,4.361,4.361,0,0,1-1.093,4.845,25.455,25.455,0,0,0,2.277,2.143,4.019,4.019,0,0,1,3.129-1.4,4.188,4.188,0,0,1,1.609.316,4.347,4.347,0,0,1,2.652,4.251,15.171,15.171,0,0,1,1.551-.017,13.9,13.9,0,0,0,1.57-.088,4.094,4.094,0,0,1,2.587-4.065,4.476,4.476,0,0,1,4.852,1.1,25.407,25.407,0,0,0,2.17-2.3,4.075,4.075,0,0,1-1.084-4.741,4.382,4.382,0,0,1,4.006-2.656l.216,0a21.24,21.24,0,0,0-.065-3.018,4.466,4.466,0,0,1-4.09-2.727m-10.12,10.26a6.05,6.05,0,1,1,6.051-6.049,6.056,6.056,0,0,1-6.051,6.049"
            transform="translate(107.868 12.283)"
            fill="#fff"
          /> */}
          <motion.path
            id="Path_168"
            data-name="Path 168"
            d="M142.361,23.582a4.359,4.359,0,0,1,1.092-4.843,24.8,24.8,0,0,0-2.277-2.141,4.176,4.176,0,0,1-4.739,1.084,4.346,4.346,0,0,1-2.648-4.253,26.428,26.428,0,0,0-3.123.107,4.1,4.1,0,0,1-2.589,4.065,4.466,4.466,0,0,1-4.851-1.1,25.074,25.074,0,0,0-2.168,2.3,4.078,4.078,0,0,1,1.08,4.741,4.48,4.48,0,0,1-4.2,2.65A18.721,18.721,0,0,0,118,29.347a4.115,4.115,0,0,1,4.077,2.587,4.361,4.361,0,0,1-1.093,4.845,25.455,25.455,0,0,0,2.277,2.143,4.019,4.019,0,0,1,3.129-1.4,4.188,4.188,0,0,1,1.609.316,4.347,4.347,0,0,1,2.652,4.251,15.171,15.171,0,0,1,1.551-.017,13.9,13.9,0,0,0,1.57-.088,4.094,4.094,0,0,1,2.587-4.065,4.476,4.476,0,0,1,4.852,1.1,25.407,25.407,0,0,0,2.17-2.3,4.075,4.075,0,0,1-1.084-4.741,4.382,4.382,0,0,1,4.006-2.656l.216,0a21.24,21.24,0,0,0-.065-3.018,4.466,4.466,0,0,1-4.09-2.727m-10.12,10.26a6.05,6.05,0,1,1,6.051-6.049,6.056,6.056,0,0,1-6.051,6.049"
            transform="translate(107.868 12.283)"
            fill="#fff"
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 2 }}
          />
          <path
            id="Path_169"
            data-name="Path 169"
            d="M53.368,165.445A4.362,4.362,0,0,1,54.46,160.6a24.845,24.845,0,0,0-2.279-2.141,4.172,4.172,0,0,1-4.738,1.084,4.346,4.346,0,0,1-2.648-4.253,26.432,26.432,0,0,0-3.123.107,4.1,4.1,0,0,1-2.589,4.065,4.466,4.466,0,0,1-4.851-1.1,24.815,24.815,0,0,0-2.168,2.3,4.078,4.078,0,0,1,1.08,4.741,4.479,4.479,0,0,1-4.2,2.65A18.915,18.915,0,0,0,29,171.209a4.114,4.114,0,0,1,4.077,2.587,4.357,4.357,0,0,1-1.092,4.845,25.459,25.459,0,0,0,2.277,2.143,4.019,4.019,0,0,1,3.129-1.4A4.188,4.188,0,0,1,39,179.7a4.343,4.343,0,0,1,2.65,4.251,15.2,15.2,0,0,1,1.553-.017,13.916,13.916,0,0,0,1.57-.088,4.094,4.094,0,0,1,2.587-4.065,4.476,4.476,0,0,1,4.852,1.1,25.4,25.4,0,0,0,2.168-2.3,4.077,4.077,0,0,1-1.082-4.741,4.382,4.382,0,0,1,4.006-2.656l.216.006a21.261,21.261,0,0,0-.065-3.02,4.467,4.467,0,0,1-4.09-2.725M43.248,175.7a6.05,6.05,0,1,1,6.051-6.049,6.056,6.056,0,0,1-6.051,6.049"
            transform="translate(26.445 142.079)"
            fill="#fff"
          />
          <text
            id="HTM"
            transform="translate(220.987 11.86)"
            fill="#fff"
            font-size="12"
            font-family="OpenSans-Bold, Open Sans"
            font-weight="700"
          >
            <tspan x="0" y="0" letter-spacing="0.089em">
              HT
            </tspan>
            <tspan y="0">M</tspan>
          </text>
          <text
            id="L"
            transform="translate(253.24 11.86)"
            fill="#fff"
            font-size="12"
            font-family="OpenSans-Bold, Open Sans"
            font-weight="700"
          >
            <tspan x="0" y="0">
              L
            </tspan>
          </text>
        </g>
      </g>
      <text
        id="PHP"
        transform="translate(100.322 305.277)"
        fill="#fff"
        font-size="12"
        font-family="OpenSans-Bold, Open Sans"
        font-weight="700"
      >
        <tspan x="0" y="0" letter-spacing="0.09em">
          P
        </tspan>
        <tspan y="0" letter-spacing="0.089em">
          H
        </tspan>
        <tspan y="0">P</tspan>
      </text>
      <text
        id="JS"
        transform="translate(387.283 172.369)"
        fill="#cfcfcf"
        font-size="10"
        font-family="OpenSans-Bold, Open Sans"
        font-weight="700"
      >
        <tspan x="0" y="0" letter-spacing="0.089em">
          J
        </tspan>
        <tspan y="0">S</tspan>
      </text>
      <g id="Group_75" data-name="Group 75">
        <g id="Group_74" data-name="Group 74" clip-path="url(#clip-path-2)">
          <text
            id="CSS"
            transform="translate(0 87.232)"
            fill="#fff"
            font-size="12"
            font-family="OpenSans-Bold, Open Sans"
            font-weight="700"
          >
            <tspan x="0" y="0" letter-spacing="0.089em">
              C
            </tspan>
            <tspan y="0" letter-spacing="0.09em">
              S
            </tspan>
            <tspan y="0">S</tspan>
          </text>
          <path
            id="Path_170"
            data-name="Path 170"
            d="M56.247,182.229h0l0,.006a1.52,1.52,0,0,0-.251.425,1.326,1.326,0,0,0-.046.14,1.513,1.513,0,0,0-.067.373,1.118,1.118,0,0,0-.01.14c0,.027-.006.048-.006.073a1.7,1.7,0,0,0,.086.431.241.241,0,0,0,.019.04,1.527,1.527,0,0,0,.224.4c.015.015.019.04.034.056l6.444,7.595a1.167,1.167,0,0,0,1.81.021,1.637,1.637,0,0,0,.019-2.082l-3.476-4.1a33.368,33.368,0,0,0,10.306-.45c15.406-2.79,26.307-17.02,24.3-31.713a1.344,1.344,0,0,0-1.262-1.245,1.773,1.773,0,0,0-.2.013,1.468,1.468,0,0,0-1.065,1.685c1.785,13.092-8.161,25.808-22.215,28.356a31.5,31.5,0,0,1-10.075.358l5.362-3.185a1.583,1.583,0,0,0,.55-1.984A1.2,1.2,0,0,0,65,176.951l-8.451,5.019a1.277,1.277,0,0,0-.306.259"
            transform="translate(51.11 139.386)"
            fill="#fff"
          />
          <path
            id="Path_171"
            data-name="Path 171"
            d="M206.594,41.877l0,0,.008,0a1.486,1.486,0,0,0,.477-.123,1.387,1.387,0,0,0,.13-.067,1.569,1.569,0,0,0,.312-.216.965.965,0,0,0,.105-.094c.019-.019.038-.029.056-.046a1.689,1.689,0,0,0,.243-.366.16.16,0,0,0,.015-.04,1.544,1.544,0,0,0,.126-.444c0-.021.015-.042.015-.063l.814-9.927a1.167,1.167,0,0,0-1.266-1.295,1.634,1.634,0,0,0-1.484,1.459l-.44,5.352a33.4,33.4,0,0,0-7.6-6.968c-12.867-8.92-30.637-6.568-39.609,5.241a1.342,1.342,0,0,0,.01,1.771,1.682,1.682,0,0,0,.153.132,1.469,1.469,0,0,0,1.946-.439c7.993-10.519,24.019-12.478,35.76-4.343a31.545,31.545,0,0,1,7.376,6.873L197.7,36.733a1.585,1.585,0,0,0-1.792,1.015,1.2,1.2,0,0,0,.768,1.668l9.523,2.428a1.338,1.338,0,0,0,.4.033"
            transform="translate(144.713 21.617)"
            fill="#fff"
          />
          <path
            id="Path_172"
            data-name="Path 172"
            d="M51.739,88.837v26.61l-6.61-6.61a1.778,1.778,0,1,0-2.514,2.514L52.262,121a1.788,1.788,0,0,0,.578.381,1.766,1.766,0,0,0,1.362,0,1.869,1.869,0,0,0,.574-.385l9.649-9.651a1.778,1.778,0,0,0-2.514-2.514L55.3,115.447l0-26.608a1.778,1.778,0,1,0-3.556,0"
            transform="translate(38.512 79.655)"
            fill="#fff"
          />
          <path
            id="Path_173"
            data-name="Path 173"
            d="M54.2,64.092a1.787,1.787,0,0,0-1.936.383l-9.649,9.649a1.778,1.778,0,0,0,2.514,2.514l6.612-6.612,0,26.6a1.778,1.778,0,0,0,3.556,0V70.026l6.612,6.612a1.778,1.778,0,1,0,2.514-2.514l-9.649-9.649a1.763,1.763,0,0,0-.578-.383"
            transform="translate(38.512 58.516)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}
