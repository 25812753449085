import React from "react";
import BIM from "../../../assets/home/bim.png";
import QPS from "../../../assets/home/qps.png";
import AI from "../../../assets/home/ai.png";
import { Link } from "react-router-dom";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
export default function Service() {
  const array = [
    {
      id: 1,
      img: BIM,
      title: "BIM",
      desp: "BIM Consultation & Design Services",
      link: "/bim",
    },
    {
      id: 2,
      img: QPS,
      title: "QOMPAS",
      desp: "Construction Tech Platform",
      link: "https://qompas.co/",
    },
    {
      id: 3,
      img: AI,
      title: "AI",
      desp: "Software Development & AI Consultation",
      link: "/ai",
    },
  ];
  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 h-auto bg-black">
      {array.map((data) => (
        <Link
          to={data.link}
          className="h-screen w-full lg:h-full relative"

          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          <img
            src={data.img}
            alt="service"
            className="w-full h-full object-cover"
          />
          <div className="absolute bottom-0 z-9 p-10 w-full">
            <p className="font-bold text-5xl sm:text-6xl text-white">
              {data.title}
            </p>
            <div className="flex justify-between items-center">
              <p className="font-thin text-2xl text-white w-1/2">{data.desp}</p>
              <p className="text-white text-4xl" >
                <HiOutlineArrowLongRight />
              </p>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
}
