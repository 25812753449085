import React from "react";
import HERO from "../../../assets/bim/hero.png";
export default function Hero() {
  return (
    <div className="h-auto lg:h-[800px] w-fill relative flex justify-center items-end ">
      <div className="bg-gray-500 w-full h-full absolute">
        <img src={HERO} alt="hero" className="h-full w-full object-cover" />
      </div>
      <div className="bg-gradient-to-t from-black w-full px-10 lg:px-0 mt-[300px] lg:mt-0 flex justify-center relative">
        <div className="w-full max-w-screen-2xl flex flex-col lg:flex-row justify-center items-center">
          <div className="w-auto ">
            <p className="text-white text-7xl sm:text-8xl xl:text-8xl font-thin text-center lg:text-end">
              BIM
            </p>
          </div>
          <div className="w-auto">
            <p className="text-white text-3xl sm:text-4xl xl:text-4xl font-thin text-center lg:text-start w-auto ">
              Services pioneer with <br className="hidden lg:block" /> extensive
              global experience
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
