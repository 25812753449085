import React from "react";
import HERO from "../../../assets/home/hero.png";
export default function Hero() {
  return (
    <div className="h-auto lg:h-[800px] w-fill relative flex justify-center items-end ">
      <div className="bg-gray-500 w-full h-full absolute">
        <img
          src={HERO}
          alt="hero"
          className="h-full w-full object-cover"
        />
      </div>
      <div className="w-full max-w-screen-2xl pb-10 px-10 lg:px-0 mt-[300px] lg:mt-0 flex flex-col lg:flex-row justify-center items-center lg:items-end relative">
        <div className="w-auto lg:w-[40%]">
          <p className="text-white text-4xl sm:text-6xl font-thin text-center lg:text-end">
            Technology Solutions Pioneer with Extensive Global Experience
          </p>
        </div>
        <div className="bg-white h-[1px] lg:h-[200px] w-[200px] lg:w-[1px] my-5 lg:my-0 mx-5"></div>
        <div className="w-auto lg:w-[40%]">
          <p className="text-white text-3xl font-light text-center lg:text-start w-auto lg:w-2/5">
            Building the Future, Digitally
          </p>
          <p className="text-white text-lg font-thin text-center lg:text-start w-auto lg:w-4/5">
            Your One-Stop Expert for BIM, Construction Tech, Software
            Development and AI Expertise.
          </p>
        </div>
      </div>
    </div>
  );
}
